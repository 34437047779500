import React, { useState, FC } from "react";

export interface LocationInputProps {
    placeHolder?: string;
    desc?: string;
    className?: string;
    onReferenceCreated: (ref: HTMLInputElement) => void
}

const LocationInput: FC<LocationInputProps> = (props: LocationInputProps) => {
    const {placeHolder = 'Ou habitez vous', desc = 'Ville'} = props;

    let inputRef: HTMLInputElement | null
    const [value, setValue] = useState<string>("");


    return (
        <div className={'w-full h-full absolute bg-black/[.6] z-50'}>
        <div className={` ${props.className} bg-white opacity-100`}>
            <div
                className={`flex z-10 flex-1 relative [ nc-hero-field-padding ] flex-shrink-0 items-center space-x-3 cursor-pointer focus:outline-none text-left`}>
                <div className="flex-grow">
                    <input
                        className={`block w-full bg-transparent border-none focus:ring-0 p-0 focus:outline-none focus:placeholder-neutral-300 xl:text-lg font-semibold placeholder-neutral-800 dark:placeholder-neutral-200 truncate`}
                        placeholder={placeHolder}
                        value={value}
                        onChange={(e) => {
                            setValue(e.currentTarget.value);
                        }}
                        ref={(reference) => {
                            inputRef = reference
                            reference ? props.onReferenceCreated(reference) : void 0
                        }}
                    />
                    <span className="block mt-0.5 text-sm text-neutral-400 font-light "></span>
                    <span className="line-clamp-1" onClick={() => inputRef?.focus()}>{desc}</span>
                </div>
            </div>
        </div>
        </div>
    );
};

export default LocationInput;
