import { Observable, throwError } from "rxjs";
import { catchError, map } from 'rxjs/operators'
import { InstantHeader } from '../../../domain/entities/instantHeader'
import { InstantsLoader } from '../../../domain/gateways/Instants.loader'
import { InstantHeaderDTO } from './dto/InstantHeaderDTO'
import { InstantMapper } from './mappers/instant.mapper'
import { InstantTypes } from "../../../domain/entities/types/InstantTypes";
import { Coordinates } from "../../../../common/domain/entities/Coordinates";
import {
    SecuredObservableAjaxHttpClient
} from "../../../../common/adapters/secondaries/real/securedObservableAjaxHttpClient";
import { ApplicationContext } from "../../../../common/configuration/application.context";
import { InstantDetails } from "../../../domain/entities/instantDetails";
import { InstantDetailsDTO } from "./dto/InstantDetailsDTO";

const moment = ApplicationContext.getInstance().momentJs()

export class ApiInstantsLoader implements InstantsLoader {

    readonly apiURL?: string;

    constructor(apiURL?: string) {
        this.apiURL = apiURL;
    }

    loadAllInstants(coords: Coordinates, radius: number, categories: InstantTypes[], date: Date): Observable<InstantHeader[]> {
        const categoryIds = JSON.stringify(this.convertCategoryToId(categories))
        const url = `${this.apiURL}/v1/instants?date=${moment(date).format()}&lat=${coords.latitude}&lng=${coords.longitude}&radius=${radius}&categoryIds=${categoryIds}&limit=50`

        return new SecuredObservableAjaxHttpClient()
            .get<{ data: { data: InstantHeaderDTO[] } }>(url)
            .pipe(
                map((response: { data: { data: InstantHeaderDTO[] } }) =>
                    response.data.data.map(instantInfoItem => InstantMapper.mapToInstantHeader(instantInfoItem))
                ),
                catchError(err => throwError(err.status.toString()))
            )
    }

    loadUpcomingInstantsByCompanyId(companyId: string): Observable<InstantHeader[]> {
        const url = `${process.env.REACT_APP_API_URL}/v1/instants/company/${companyId}/upcoming?date=${moment().format()}`

        return new SecuredObservableAjaxHttpClient()
            .get<{ data: InstantHeaderDTO[] }>(url)
            .pipe(
                map((response: { data: InstantHeaderDTO[] }) => response.data.map(item => InstantMapper.mapToInstantHeader(item))),
                catchError(err => throwError(err.status.toString()))
            )
    }

    loadInstantDetails(instantId: string): Observable<InstantDetails> {
        const dateTime = moment().add(1, 'seconds').format()
        const url = `${process.env.REACT_APP_API_URL}/v1/instants/${instantId}/current-activeTime?date=${dateTime}`
        return new SecuredObservableAjaxHttpClient()
            .get<{ data: InstantDetailsDTO }>(url)
            .pipe(
                map((response: { data: InstantDetailsDTO }) => {
                    return InstantMapper.mapToInstantDetails(response.data)
                }),
                catchError(err => throwError(err.status.toString()))
            )
    }

    private convertCategoryToId(categories: InstantTypes[]): number[] {
        const categoryID: { [key: string]: number } = {
            'cultural' : 1,
            'sportive' : 2,
            'discovery': 3,
            'gourmand' : 4,
            'festive'  : 5,
            'heart'    : 6,
            'info'     : 7,
            'offer'    : 8,
            'gift'     : 9,
            'musical'  : 10
        }
        return categories.map(category => categoryID[category])
    }
}
