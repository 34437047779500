import React from 'react'
import ReactDOM from 'react-dom/client'
//
import 'rc-slider/assets/index.css';
// STYLE
import './common/styles/index.scss';
import './index.css'
import './common/fonts/line-awesome-1.3.0/css/line-awesome.css';
import './common/styles/custom.css';
//
import App from './App'
import reportWebVitals from './reportWebVitals'

import './common/configuration/i18n/i18n';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
)
root.render(
  //<React.StrictMode>
    <App />
  //</React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
