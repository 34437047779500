export const InstantEnI18n = {
    instant_schedule          : "{{date}} from {{startHour}} to {{endHour}}",
    special_offers            : 'Bon plan',
    time_left_status_starts_on: 'Starts',
    time_left_status_ends_on  : 'Ends',
    time_left_status_expired  : 'Ended',
    tomorrow                  : 'Tomorrow',
    today                     : 'Today',
    on                        : 'on',
    in                        : 'in',
    events                    : 'Events',
    cultural                  : 'Cultural',
    sportive                  : 'Sport',
    discovery                 : 'Discovery',
    gourmand                  : 'Gourmand',
    festive                   : 'Festive',
    heart                     : 'Heart',
    info                      : 'Info',
    offer                     : 'Offer',
    gift                      : 'Gift',
    musical                   : 'Musical',
    chooseTime                : 'Hour',
    other_instants            : 'Other instants of {{name}}',
    you_can_like              : 'You can also like it',
    our_suggestions           : 'Our suggestions for outings',
    popular_instants          : 'The most popular outings in Paris at the moment'
}
