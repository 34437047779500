import React, { ReactElement, useEffect, useRef } from 'react';
import { createRoot, Root } from "react-dom/client";
import { InstantHeader } from "../../../../domain/entities/instantHeader";


interface Props {
    map: google.maps.Map;
    instant: InstantHeader;
    children: ReactElement;
    onMarkerClick: () => void;
    onRootElemCreated: (reference: Root) => void;
}

const InstantMarker = (props: Props) => {

    const markerRef = useRef<google.maps.marker.AdvancedMarkerElement>()
    const rootRef = useRef<Root>()

    useEffect(() => {
        const container = document.createElement("div")
        rootRef.current = createRoot(container)
        markerRef.current = new google.maps.marker.AdvancedMarkerElement({
            position: {lat: props.instant.coordinate.latitude, lng: props.instant.coordinate.longitude},
            map     : props.map,
            content : container
        })

        rootRef.current.render(props.children)
        markerRef.current.addListener("gmp-click", () => props.onMarkerClick());

        props.onRootElemCreated(rootRef.current)
    }, [])

    return <></>
};

export default InstantMarker;
