import { CompanyDto, CompanyHeaderDTO } from "../dto/company.dto";
import { Company } from "../../../../domain/entities/company";
import { CompanyBuilder } from "../../../../domain/entities/company.builder";
import { CompanyMembership } from "../../../../domain/entities/companyMembership";
import { ClickCollectPicture, OpeningHoursType } from "../../../../domain/entities/types/company.types";
import { CompanyHeader } from "../../../../domain/entities/companyHeader";
import { CompanyHeaderBuilder } from "../../../../domain/entities/companyHeader.builder";
import { CompanyCategories } from "../../../../domain/entities/types/company.categories";

export class CompanyMapper {

    static mapCompanyDTO(company: CompanyDto): Company {
        const openingHours: OpeningHoursType [] = []
        if (company.openHours.length > 0)
            company.openHours.map(item => openingHours.push({
                    id       : item.id,
                    daytype  : item.type,
                    startTime: item.startTime,
                    endTime  : item.endTime,
                    isPaused : item.enabled === 'false'
                })
            )

        const coverImagePieces = company.coverImage.split('/');
        const profileImagePieces = company.profileImage.split('/');
        const coverImage = new File([company.coverImage], coverImagePieces[coverImagePieces.length - 1], {type: "image/jpeg"})
        const profileImage = new File([company.profileImage], profileImagePieces[profileImagePieces.length - 1], {type: "image/jpeg"})

        const clickCollectPictures: ClickCollectPicture[] = []

        if (company.cacImagesPath && company.cacImagesPath.length > 0)
            company.cacImagesPath.map(picture => {
                clickCollectPictures.push({
                    id        : picture.rank,
                    pictureUrl: picture.url,
                    picture   : undefined
                })
            })

        const description = company.description ?
            company.description.replaceAll('<b>', '<strong>')
                .replaceAll('</b>', '</strong>')
                .replaceAll('<i>', '<em>')
                .replaceAll('</i>', '</em>') : '';

        return new CompanyBuilder()
            .withId(company.companyId)
            .withName(company.name)
            .withMembership(new CompanyMembership(company.subscription.membership,
                company.subscription.allowedInstant,
                company.subscription.allowedConversation,
                company.subscription.allowedNotificationPerInstant,
                company.subscription.allowedBroadcast,
                company.subscription.hasSubdomain,
                company.subscription.hasClickAndCollect))
            .withType(company.type)
            .withCategory(CompanyCategories[company.category] ?? company.category)
            .withAddress(company.address)
            .withZipCode(company.zipCode)
            .withCity(company.city)
            .withLongitude(company.longitude)
            .withLatitude(company.latitude)
            .withCoverImage(coverImage)
            .withProfileImage(profileImage)
            .withCoverImageUrl(company.coverImage)
            .withProfileImageUrl(company.profileImage)
            .withDescription(description)
            .withWebsite(company.website)
            .withEmail(company.email)
            .withPhoneNumber(company.phoneNumber)
            .withFollowersCount(company.followersCount)
            .withOpeningHours(openingHours)
            .withPremiumSubdomain(company.premiumSubdomain)
            .withActiveClickCollect(company.cacActive)
            .withDescriptionClickCollect(company.cacDescription)
            .withPicturesClickCollect(clickCollectPictures)
            .build()
    }

    static mapCompanyHeaderDTO(data: CompanyHeaderDTO[]): CompanyHeader[] {
        return data.filter(item => item.type === 'commerce')
            .map(company =>
                new CompanyHeaderBuilder()
                    .withId(company.id)
                    .withName(company.name)
                    .withImage(company.image)
                    .withAddress(company.address)
                    .withStatus(company.followed)
                    .withType(company.type)
                    .withLatitude(company.latitude)
                    .withLongitude(company.longitude)
                    .withDescription(company.description)
                    .withCity(company.city)
                    .withCategory(CompanyCategories[company.category] ?? company.category)
                    .build()
            )
    }

}
