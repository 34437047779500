import React from "react";
import { InstantDetails } from "../../../../domain/entities/instantDetails";
import { ApplicationContext } from "../../../../../common/configuration/application.context";
import i18n from "../../../../../common/configuration/i18n/i18n";

const moment = ApplicationContext.getInstance().momentJs()

interface Props {
    instant: InstantDetails;
}

export const InstantDetailsDescription = (props: Props): JSX.Element => {

    return (
        <div className="listingSection__wrap">

            <h2 className="text-2xl font-semibold">A propos</h2>

            <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"/>

            <div dangerouslySetInnerHTML={{__html: props.instant.description}}/>

            <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"/>

            <div>
                <h3 className="text-lg font-semibold">Quand ?</h3>

                {props.instant.activationDayType === 'fixed' ? (
                    <div className="mt-3 text-neutral-500 dark:text-neutral-400 w-full text-sm sm:text-base">
                        <div className="flex space-x-10 justify-between p-3 bg-neutral-100 dark:bg-neutral-800 rounded-lg">
                            <span>Début</span>
                            <span>Le {moment(props.instant.dateStart).format('DD MMM YYYY à HH:mm')}</span>
                        </div>
                        <div className="flex space-x-10 justify-between p-3">
                            <span>Fin</span>
                            <span>Le {moment(props.instant.dateEnd).format('DD MMM YYYY à HH:mm')}</span>
                        </div>
                    </div>
                ) : (
                    <div className="mt-3 text-neutral-500 dark:text-neutral-400 w-full text-sm sm:text-base">
                        <div className="flex space-x-10 justify-between p-3 bg-neutral-100 dark:bg-neutral-800 rounded-lg">
                            <span>{i18n.t(`company.${props.instant.activationDayType}`)}</span>
                            <span>{moment(props.instant.dateStart).format('HH:mm')} - {moment(props.instant.dateEnd).format('HH:mm')}</span>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );

};

