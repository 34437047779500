import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Epic, ofType, StateObservable } from "redux-observable";
import { LOAD_LAST_CREATED_COMPANIES, LoadLastCreatedCompaniesAction } from "./actionTypes";
import { loadLastCreatedCompaniesFailed, loadLastCreatedCompaniesSucceeded } from "./actions";
import { CompanyHeader } from "../../domain/entities/companyHeader";
import { CompanyService } from "../../domain/gateway/company.service";
import { AppState } from "../../../redux-configuration/AppState";

export const loadLastCreatedCompaniesEpic: Epic = (action$: Observable<LoadLastCreatedCompaniesAction>,
                                                   store: StateObservable<AppState>,
                                                   {companyService}: { companyService: CompanyService }) =>
    action$.pipe(
        ofType(LOAD_LAST_CREATED_COMPANIES),
        switchMap(action => companyService.getLastCreatedCompanies(action.payload)
            .pipe(
                map((data: CompanyHeader[]) => loadLastCreatedCompaniesSucceeded(data)),
                catchError(err => of(loadLastCreatedCompaniesFailed(err)))
            )
        )
    )
