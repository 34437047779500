import React, { useEffect } from "react";
import MapSection from "./components/MapSection";
import { InstantHeader } from "../../../instant/domain/entities/instantHeader";
import { Coordinates } from "../../../common/domain/entities/Coordinates";
import SectionDowloadApp from "./components/SectionDowloadApp";
import { TypedCategoryType } from "../../../instant/domain/entities/types/InstantTypes";
import OtherCompaniesListing
    from "../../../company/adapters/primaries/components/otherCompanies/otherCompanies.listing";
import { CompanyHeader } from "../../../company/domain/entities/companyHeader";
import i18n from "../../../common/configuration/i18n/i18n";
import VerticalInstantsListing from "../../../instant/adapters/primaries/components/list/vertical/verticalInstants.listing";
import { HomepageInstantsSelection } from "./constants";
import { AppEventBuilder } from "../../../common/domain/entities/logging/appEvent.builder";
import { AppEventsTypes } from "../../../common/domain/appEvents.Type";
import { logEvent } from "../../../common/usecases/logging/logging.action";
import { Dispatch } from "redux";
import { LogEventAction } from "../../../common/usecases/logging/analytics.types";
import { useDispatch } from "react-redux";
import { Helmet } from "react-helmet";

interface Props {
    instants: InstantHeader[];
    companies: CompanyHeader[] | undefined;
    retrieveInstantsListing: (coordinates: Coordinates, distance: number, clearExistingInstants: boolean) => void;
    setCategory: (cat: TypedCategoryType) => void;
    loadLastCreatedCompanies: () => void;
    fetchCoordinates: Coordinates | undefined;
    fetchInstantLoading: boolean;
}

export function HomeContainer(props: Props) {

    const dispatch: Dispatch<LogEventAction> = useDispatch();

    useEffect(() => {
        props.loadLastCreatedCompanies()
        const appEventBuilder = (new AppEventBuilder())
            .withNamespace(AppEventsTypes.namespace.home_map)
            .withAction(AppEventsTypes.action.display)
        dispatch(logEvent(appEventBuilder.build()))
    }, [])

    return (
        <div className="nc-PageHome2 relative overflow-hidden z-0">
            <Helmet>
                <title>Vivez Ici | En ce moment</title>
                <meta property="og:title" content="VivezIci | En ce moment"/>
                <meta property="og:url" content="https://vivezici.fr"/>
                <meta property="og:description"
                      content="Un utilisateur, c’est un habitant, un travailleur, un touriste, un client, un usager, un commerçant, une entreprise, une mairie : VIVEZ ICI regroupe des informations éparses et les rend accessibles en un clin d’œil."/>
                <meta property="og:image" content={require('../../../assets/vivez-ici-logo-square.png')}/>
            </Helmet>

            <MapSection setCategory={(cat) => props.setCategory(cat)}
                        fetchInstantLoading={props.fetchInstantLoading}
                        fetchCoordinates={props.fetchCoordinates}
                        loadInstant={(coords, fetchDistance, clearExistingInstants) => {
                            props.retrieveInstantsListing(coords, fetchDistance, clearExistingInstants)
                        }}
                        instants={props.instants}/>

            {props.instants.length > 0 && <VerticalInstantsListing title={i18n.t("instant.our_suggestions")}
                                                                   subTitle={i18n.t("instant.popular_instants")}
                                                                   instants={HomepageInstantsSelection}/>}

            {props.companies && props.companies.length > 0 &&
                <OtherCompaniesListing otherCompanies={props.companies}
                                       title={i18n.t("company.joined_us")}
                                       subTitle={i18n.t("company.company_types")}/>}

            <div className="container relative space-y-24 mb-20 lg:space-y-28">
                <SectionDowloadApp/>
            </div>
        </div>
    );
}
