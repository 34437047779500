import React, { FC } from "react";

export interface Props {
    imageUrl: string;
    title: string;
}

const CompanyDetailsHeader: FC<Props> = ({imageUrl, title}) => {
    return (
        <header className="rounded-md sm:rounded-xl">
            <div className={'nc-SectionHero3 relative'} data-nc-id="SectionHero3">
                <div className="relative aspect-w-1 aspect-h-1 sm:aspect-w-4 sm:aspect-h-3 lg:aspect-w-16 lg:aspect-h-9 xl:aspect-h-8 ">
                    <img src={imageUrl}
                         alt={title}
                         className="absolute inset-0 object-cover rounded-xl"/>
                </div>
            </div>
        </header>
    );
};

export default CompanyDetailsHeader;
