import { SendEmailToCompanyState } from "../../configuration/state";
import {
    SEND_EMAIL_TO_COMPANY,
    SEND_EMAIL_TO_COMPANY_FAILED,
    SEND_EMAIL_TO_COMPANY_SUCCEEDED,
    SendEmailToCompanyActionTypes
} from "./actionTypes";

const initialState: SendEmailToCompanyState = {
    loading: false,
    success: undefined,
    error  : undefined
}

export const sendEmailToCompanyReducer = (state = initialState, action: SendEmailToCompanyActionTypes): SendEmailToCompanyState => {
    switch (action.type) {
        case SEND_EMAIL_TO_COMPANY:
            return {
                loading: true,
                success: undefined,
                error  : undefined
            }
        case SEND_EMAIL_TO_COMPANY_SUCCEEDED:
            return {
                loading: false,
                success: true,
                error  : undefined
            }
        case SEND_EMAIL_TO_COMPANY_FAILED:
            return {
                loading: false,
                success: false,
                error  : action.payload
            }
        default:
            return state
    }
}
