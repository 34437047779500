import { Observable, throwError } from 'rxjs';
import { catchError, map } from "rxjs/operators";
import { CityService } from "../../../domain/gateway/cityService";
import { CityHeader } from "../../../domain/entities/CityHeader";
import { CityDTO } from "./dto/city.DTO";
import { CityMapper } from "./mapper/cityMapper";
import { SecuredObservableAjaxHttpClient } from "./securedObservableAjaxHttpClient";

export class ApiCityService implements CityService {

    readonly apiURL?: string;

    constructor(apiURL?: string) {
        this.apiURL = apiURL;
    }

    loadCities(): Observable<CityHeader[]> {
        const url = `${this.apiURL}/v1/syntheticProAccount/cities`
        return new SecuredObservableAjaxHttpClient().get<{ data: CityDTO[] }>(url)
            .pipe(
                map(response => CityMapper.mapDataToCities(response.data)),
                catchError(() => throwError('ERROR'))
            )
    }

}
