const namespace = {
    pro_instant: 'pro_instant',
    pro_web    : 'pro_web',
    home_map   : 'home_map',
}

const action = {
    go     : 'go',
    click  : 'click',
    display: 'display',
    preview: 'preview',
}
const itemType = {
    instant : 'instant',
    business: 'business',
}

export const AppEventsTypes = {
    namespace,
    action,
    itemType
}
