import React, { FC } from "react";
import { Link } from "react-router-dom";
import NcImage from "../../../../../common/adapters/primaries/components/NcImage/NcImage";
import { CompanyHeader } from "../../../../domain/entities/companyHeader";

export interface CardCategory5Props {
    company: CompanyHeader
}

const OtherCompanyItem: FC<CardCategory5Props> = ({company}) => {
    return (
        <Link to={company.publicPath()}
            className={`nc-CardCategory5 flex flex-col`}
            data-nc-id="CardCategory5">
            <div className={`flex-shrink-0 relative w-full aspect-w-4 aspect-h-4 h-0 rounded-2xl overflow-hidden group`}>
                <NcImage src={company.image.replace('dev.','')}
                         className="object-cover w-full h-full rounded-2xl"/>
                <span className="opacity-0 group-hover:opacity-100 absolute inset-0 bg-black bg-opacity-10 transition-opacity"></span>
            </div>

            <div className="mt-4 px-3 truncate">
                <p className={`text-center sm:text-lg text-neutral-900 dark:text-neutral-100 font-medium truncate`}>
                    {company.name}
                </p>

                <span className={`block text-center mt-2 text-sm text-neutral-6000 dark:text-neutral-400`}>
                    {company.category}
                </span>
            </div>
        </Link>
    );
};

export default OtherCompanyItem;
