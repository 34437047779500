import { CityHeader } from "./CityHeader";

export class CityHeaderBuilder {
    protected _name!: string
    protected _zipCode!: number
    protected _preferred!: boolean

    withName(value: string): CityHeaderBuilder {
        this._name = value
        return this
    }

    withZipCode(value: number): CityHeaderBuilder {
        this._zipCode = value
        return this
    }

    withPreferred(value: boolean): CityHeaderBuilder {
        this._preferred = value
        return this
    }

    fromCity(city: CityHeader): CityHeaderBuilder {
        this._name = city.name
        this._zipCode = city.zipCode
        this._preferred = city.preferred
        return this
    }

    build(): CityHeader {
        return new CityHeader(
            this._name,
            this._zipCode,
            this._preferred
        )
    }
}
