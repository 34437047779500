import {
    InstantListingFailedAction,
    InstantListingSucceededAction,
    INSTANTS_LISTING_LOADING_FAILED,
    INSTANTS_LISTING_SUCCEEDED,
    RETRIEVE_INSTANTS_LISTING, RetrieveInstantsListingAction
} from "./instantsListing.types";
import { InstantHeader } from "../../domain/entities/instantHeader";
import { Coordinates } from "../../../common/domain/entities/Coordinates";

export const retrieveInstantsListing = (coordinates: Coordinates, distance: number, date: Date = new Date(), clearExistingList: boolean = false): RetrieveInstantsListingAction => ({
    type   : RETRIEVE_INSTANTS_LISTING,
    payload: {coordinates, distance, date, clearExistingList}
})

export const InstantsListingLoaded = (instants: InstantHeader[]): InstantListingSucceededAction => ({
    type   : INSTANTS_LISTING_SUCCEEDED,
    payload: instants
})

export const InstantsListingLoadingFailed = (error: string): InstantListingFailedAction => ({
    type   : INSTANTS_LISTING_LOADING_FAILED,
    payload: error
})
