import BackgroundSection from "../../../../common/adapters/primaries/components/BackgroundSection/BackgroundSection";
import React from "react";

const SectionDowloadApp = () => {
    return (
        <div className="relative pb-0 pt-24 lg:py-32 xl:py-40 2xl:py-48">
            <BackgroundSection className="bg-neutral-100 bg-opacity-80 dark:bg-opacity-100 ">
                <img className="absolute inset-0 w-full h-full object-cover rounded-3xl object-right"
                     src={require("../../../../common/images/dowloadAppBG.png")}
                     alt="dowload App"/>

                <div className="hidden lg:block absolute right-0 bottom-0 max-w-xl xl:max-w-2xl rounded-3xl overflow-hidden">
                    <img src={require("../../../../common/images/appRightImg.png")} alt="dowload App"/>
                </div>

                <div className="absolute right-0 top-0 max-w-2xl">
                    <img src={require("../../../../common/images/appRightImgTree.png")} alt="dowload App"/>
                </div>

                <div className="absolute left-0 bottom-10 max-w-2xl">
                    <img src={require("../../../../common/images/appSvg1.png")} alt="dowload App"/>
                </div>
            </BackgroundSection>

            <div className="relative inline-block ">
                <h3 className="text-5xl md:text-6xl xl:text-7xl font-bold text-neutral-800">
                    Mobile App
                </h3>

                <span className="block mt-7 max-w-md text-neutral-6000">
                    Télécharger l&apos;application VivezIci
                </span>

                <div className="flex space-x-3 mt-10 sm:mt-14">
                    <a href="#" target="_blank" rel="noopener noreferrer">
                        <img src={require("../../../../common/images/btn-ios.png")} alt="App Store"/>
                    </a>
                    <a href="#" target="_blank" rel="noopener noreferrer">
                        <img src={require("../../../../common/images/btn-android.png")} alt="Google play"/>
                    </a>
                </div>

                <img className="hidden lg:block absolute lg:left-full lg:top-0 xl:top-1/2 z-10  lg:max-w-sm 2xl:max-w-none"
                     src={require('../../../../common/images/appSvg2.png')}
                     alt="dowload App"/>

                <div className="block lg:hidden mt-10 max-w-2xl rounded-3xl overflow-hidden">
                    <img src={require("../../../../common/images/appRightImg.png")} alt="dowload App"/>
                </div>
            </div>
        </div>
    );
};

export default SectionDowloadApp;
