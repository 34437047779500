import React from "react";
import { Link } from "react-router-dom";
import { InstantHeader } from "../../../../../domain/entities/instantHeader";
import { getInstantMarker } from "../../../../../domain/entities/types/instantIcons";
import { InstantCategories } from "../../../../../domain/entities/types/InstantTypes";
import { ApplicationContext } from "../../../../../../common/configuration/application.context";
import Badge from "../../../../../../common/adapters/primaries/components/Badge/Badge";
import NcImage from "../../../../../../common/adapters/primaries/components/NcImage/NcImage";

export interface Props {
    instant: InstantHeader;
}

const moment = ApplicationContext.getInstance().momentJs()

const VerticalInstantItem = (props: Props): JSX.Element => {

    const renderContent = () => {
        return (
            <div className={"p-4 space-y-4"}>
                <div className="space-y-2">
                    <span className="text-sm text-neutral-500 dark:text-neutral-400">
                        <Badge name={InstantCategories[props.instant.category]} color="green"/>
                    </span>

                    <div className="flex items-center space-x-2">
                        <h3 className={'font-medium capitalize text-lg'}>
                            <span className="line-clamp-1">{props.instant.title}</span>
                        </h3>
                    </div>

                    <div className="text-neutral-500 dark:text-neutral-400 text-sm space-x-2">
                        <span className="line-clamp-3">{props.instant.description}</span>
                    </div>
                </div>

                <div className="w-14 border-b border-neutral-100 dark:border-neutral-800"></div>

                <div className="flex justify-between items-center">
                    <span className="text-sm font-semibold">
                        {props.instant.dateStart && moment(props.instant.dateStart).format('DD MMM YYYY - HH:mm')}
                    </span>

                    <span className="flex items-center space-x-2 text-base font-semibold">
                        <svg xmlns="http://www.w3.org/2000/svg"
                             className="h-5 w-5"
                             fill="none"
                             viewBox="0 0 24 24"
                             stroke="currentColor">
                            <path strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth={1.5}
                                  d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"/>
                            <path strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth={1.5}
                                  d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"/>
                        </svg>

                        <span className="text-sm text-neutral-500 font-normal">
                            {props.instant.city}
                          </span>
                    </span>
                </div>
            </div>
        );
    };

    return (
        <div
            className={'nc-StayCard group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-2xl overflow-hidden will-change-transform hover:shadow-xl transition-shadow'}
            data-nc-id="StayCard">
            <div className="relative w-full">
                <Link to={props.instant.publicPath()} className={`block aspect-w-4 aspect-h-4`}>
                    <NcImage src={props.instant.picture}/>
                </Link>

                <div className="absolute right-3 top-3 z-[1]">
                    <img width={33} height={37} src={getInstantMarker(props.instant.category)}
                         alt={props.instant.category}/>
                </div>
            </div>

            <Link to={props.instant.publicPath()}>{renderContent()}</Link>
        </div>
    );
};

export default VerticalInstantItem;
