import { combineEpics, createEpicMiddleware } from 'redux-observable'
import { instantRootEpics } from "../instant/configuration/instantContextRootEpics.redux";
import { instantEpicsDependencies } from "../instant/configuration/instantContextEpicsDependencies.redux";
import { companyRootEpic } from "../company/configuration/rootEpic";
import { companyEpicDependencies } from "../company/configuration/epic.dependencies";
import { commonRootEpics } from "../common/configuration/commonContextRootEpics.redux";
import { commonEpicsDependencies } from "../common/configuration/commonContextEpicsDependencies.redux";

export const rootEpics = combineEpics(
    instantRootEpics,
    companyRootEpic,
    commonRootEpics
)

export const epicsMiddleware = createEpicMiddleware({
    dependencies: {
        ...instantEpicsDependencies,
        ...companyEpicDependencies,
        ...commonEpicsDependencies
    }
})
