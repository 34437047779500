export const CompanyFrI18n = {
    all_days             : "Tous les jours",
    mondays_to_fridays   : "Du lundi au vendredi",
    mondays_to_saturdays : "Du lundi au samedi",
    tuesdays_to_saturdays: "Du mardi au samedi",
    saturdays_and_sundays: "Le samedi et dimanche",
    all_mondays          : "Tous les lundi",
    all_tuesdays         : "Tous les mardi",
    all_wednesdays       : "Tous les mercredi",
    all_thursdays        : "Tous les jeudi",
    all_fridays          : "Tous les vendredi",
    all_saturdays        : "Tous les samedi",
    all_sundays          : "Tous les dimanche",
    monday               : 'Lundi',
    tuesday              : 'Mardi',
    wednesday            : 'Mercredi',
    thursday             : 'Jeudi',
    friday               : 'Vendredi',
    saturday             : 'Samedi',
    sunday               : 'Dimanche',
    closed               : 'Fermé',
    followers            : "abonnées",
    france               : "France",
    about                : "A propos",
    opening_hours        : "Horaires d'ouverture",
    address              : "Adresse",
    contact_us           : "Contactez-nous",
    contact              : "Contactez",
    first_name           : "Prénom",
    last_name            : "Nom",
    email                : "Email",
    phone                : "Téléphone",
    subject              : "Sujet",
    message              : "Message",
    send                 : "Envoyer",
    other_companies      : "Les autres commerces de la ville",
    our_instants         : "Nos instants",
    instant_types        : "évenements, offres et services",
    company_types        : "Découvrez les commerces, associations et entreprises",
    joined_us            : "Ils viennent de nous rejoindre"
};
