import { LoadCompanyDetailsState } from "../../configuration/state";
import {
    LOAD_COMPANY_DETAILS,
    LOAD_COMPANY_DETAILS_FAILED,
    LOAD_COMPANY_DETAILS_SUCCEEDED,
    LoadCompanyDetailsActionTypes
} from "./actionTypes";

const initialState: LoadCompanyDetailsState = {
    loading: false,
    company: undefined,
    error  : undefined
}

export const loadCompanyDetailsReducer = (state = initialState, action: LoadCompanyDetailsActionTypes): LoadCompanyDetailsState => {
    switch (action.type) {
        case LOAD_COMPANY_DETAILS:
            return {
                loading: true,
                company: undefined,
                error  : undefined
            }
        case LOAD_COMPANY_DETAILS_SUCCEEDED:
            return {
                loading: false,
                company: action.payload,
                error  : undefined
            }
        case LOAD_COMPANY_DETAILS_FAILED:
            return {
                loading: false,
                company: undefined,
                error  : action.payload
            }
        default:
            return state
    }
}
