import React, { useEffect, useRef, useState } from 'react';
import { InstantHeader } from "../../../../domain/entities/instantHeader";
import { InstantParameters } from "../../../../configuration/assets/instant.parameters";
import InstantMarker from "./marker";
import InstantCardContainer from "../../components/card/InstantCardContainer";
import { Coordinates } from "../../../../../common/domain/entities/Coordinates";
import { DistanceUtils } from "../../../../../common/domain/entities/specifications/distanceUtils";
import { useNavigate } from "react-router-dom";
import { Root } from "react-dom/client";
import LocationInput from "./LocationInput";
import { useLocalStorage } from "react-use";

interface Props {
    instants: InstantHeader[];
    loadInstant: (coords: Coordinates, fetchDistance: number) => void;
    center: Coordinates | undefined;
    removeAllMarker: boolean;
}

const Map = (props: Props) => {
    const route = useNavigate()

    const [cityLatLng, setCityLatLng] = useLocalStorage('ownCity')

    const [map, setMap] = useState<google.maps.Map | undefined>(undefined);

    const mapDivRef = useRef<HTMLDivElement>(null);
    const allMarkers = useRef<Root[]>([]);

    let locationElem: HTMLInputElement

    const mapOptions = {
        mapId           : 'c9a877c18d234e0d',
        center          : toLatLng(props.center) ?? cityLatLng as google.maps.LatLng ?? InstantParameters.mapDefaultRegion,
        zoom            : 16,
        disableDefaultUI: false,
    };

    useEffect(() => {
        if (mapDivRef.current) {
            setMap(new window.google.maps.Map(mapDivRef.current, mapOptions));
        }
    }, []);


    useEffect(() => {
        if (map && map.getCenter() !== undefined) {
            const boundChangesListener = map.addListener('bounds_changed', () => {
                boundChangesHandler();
            })

            const autocomplete = initAutoComplete();

            return () => {
                google.maps.event.removeListener(boundChangesListener)
                google.maps.event.clearInstanceListeners(autocomplete)
            }
        }
    }, [map]);

    useEffect(() => {
        if (props.removeAllMarker) {
            allMarkers.current.map((markerRootElem) => {
                setTimeout(() => markerRootElem.unmount())
            })
        }
    }, [props.removeAllMarker])

    return (
        <>
            <div ref={mapDivRef} id="map" className={'instant-map-container'}/>
            {
                map && props.instants.map((instant) =>
                    <InstantMarker key={instant.id}
                                   onRootElemCreated={(root) => allMarkers.current.push(root)}
                                   map={map}
                                   onMarkerClick={() => {
                                       route(instant.publicPath())
                                   }}
                                   instant={instant}>
                        <InstantCardContainer mapTopLatitude={map.getBounds()?.getNorthEast().lat() as number}
                                              mapRightLongitude={map.getBounds()?.getNorthEast().lng() as number}
                                              mapBottomLatitude={map.getBounds()?.getSouthWest().lat() as number}
                                              mapLeftLongitude={map.getBounds()?.getSouthWest().lng() as number}
                                              instant={instant}/>
                    </InstantMarker>
                )
            }
            {
                !cityLatLng && <LocationInput onReferenceCreated={(ref) => locationElem = ref}
                                              className="absolute bg-[#FFF] flex-[1.5] rounded-full w-1/2 top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2"/>
            }
        </>
    )

    function boundChangesHandler() {
        if (map && map.getCenter() !== undefined) {
            const mapWindowLength = DistanceUtils.calculateDistanceInMeter(
                toCoordinate(map.getBounds()?.getNorthEast() as google.maps.LatLng),
                toCoordinate(map.getBounds()?.getSouthWest() as google.maps.LatLng),
            )
            props.loadInstant(toCoordinate(map.getCenter() as google.maps.LatLng), parseInt((mapWindowLength / 2).toString()))
        }
    }

    function initAutoComplete() {
        const autocomplete = new google.maps.places.Autocomplete(locationElem, {
            fields               : ["geometry"],
            types                : ['(cities)'],
            componentRestrictions: {country: "fr"},
            strictBounds         : true,
        });
        autocomplete.setBounds({north: 49, east: 2.5, south: 48.77, west: 2.15})
        autocomplete.addListener('place_changed', () => {
            const place = autocomplete.getPlace();
            if (map && place.geometry?.location) {
                map.moveCamera({center: place.geometry.location})
                setCityLatLng(place.geometry.location)
            }
        })
        return autocomplete;
    }

    function toCoordinate(googleCoords: google.maps.LatLng): Coordinates {
        return {
            latitude : googleCoords.lat(),
            longitude: googleCoords.lng(),
        }
    }

    function toLatLng(coords: Coordinates | undefined): google.maps.LatLngLiteral | undefined {

        return coords ? {
            lat: coords.latitude,
            lng: coords.longitude,
        } : undefined
    }
};

export default Map;
