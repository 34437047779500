import { Instant } from './instant';
import { InstantTypes } from "./types/InstantTypes";
import { InstantTimeSpecification } from "./specifications/instant.time.specification";
import { ActivationDayType } from "./types/timeType";
import { sanitizeUrl } from "../../../common/utils/urlSanitizer";

export class InstantDetails extends Instant {

    constructor(
        protected _id: string,
        protected _title: string,
        private _city: string,
        private _address: string,
        private _zipCode: number,
        private _imagesURL: string[],
        protected _description: string,
        protected _shortDescription: string,
        protected _category: InstantTypes,
        protected _latitude: number,
        protected _longitude: number,
        private _activationDayType: ActivationDayType,
        protected _activationStartDate: Date,
        protected _activationEndDate: Date,
        protected _delay: string,
        protected _originalStartDate: Date,
        protected _businessId: string
    ) {
        super(_id, _title, _description, _category, _latitude, _longitude, _delay, _activationStartDate, _activationEndDate, _originalStartDate, _businessId)
    }

    get address(): string {
        return this._address
    }

    get shortDescription(): string {
        return this._shortDescription
    }

    get city(): string {
        return this._city
    }

    get zipCode(): number {
        return this._zipCode
    }

    get activationDayType(): ActivationDayType {
        return this._activationDayType
    }

    get imagesURL(): string[] {
        return this._imagesURL
    }

    get delay(): string {
        return this._delay
    }

    get humanizeInstantSchedule(): string {
        return InstantTimeSpecification.humanizeInstantSchedule(this._activationStartDate, this._activationEndDate)
    }

    formattedTimeLeftInSeconds(): string {
        return InstantTimeSpecification.formatTimeLeft(this.timeLeftInSecond())
    }

    formattedTimeLeftInMinutes(): string {
        return InstantTimeSpecification.formatTimeLeftInMinutes(this.timeLeftInSecond())
    }

    formattedTimeLeftStatus(): string {
        return InstantTimeSpecification.timeLeftStatus(this.timeLeftInSecond(), this.isStarted())
    }

    publicPath(): string {
        return `/${sanitizeUrl(this._city.trim())}/instant/${sanitizeUrl(this._title.trim())}/${this._id}`
    }
}
