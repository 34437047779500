export const CompanyEnI18n = {
    all_days             : "All days",
    mondays_to_fridays   : "Monday - Friday",
    mondays_to_saturdays : "Monday - Saturday",
    tuesdays_to_saturdays: "Tuesday - Saturday",
    saturdays_and_sundays: "Saturday - Sunday",
    all_mondays          : "All mondays",
    all_tuesdays         : "All tuesdays",
    all_wednesdays       : "All wednesdays",
    all_thursdays        : "All thursdays",
    all_fridays          : "All fridays",
    all_saturdays        : "All saturdays",
    all_sundays          : "All sundays",
    monday               : 'Monday',
    tuesday              : 'Tuesday',
    wednesday            : 'Wednesday',
    thursday             : 'Thursday',
    friday               : 'Friday',
    saturday             : 'Saturday',
    sunday               : 'Sunday',
    closed               : 'Closed',
    followers            : "followers",
    france               : "France",
    about                : "About",
    opening_hours        : "Opening hours",
    address              : "Address",
    contact_us           : "Contact us",
    contact              : "Contact",
    first_name           : "First name",
    last_name            : "Last name",
    email                : "Email",
    phone                : "Phone number",
    subject              : "Subject",
    message              : "Message",
    send                 : "Send",
    other_companies      : "Other commerces of the city",
    our_instants         : "Our instants",
    instant_types        : "events, offers and services",
    company_types        : "Discover the commerces, associations and enterprises",
    joined_us            : "They have just joined us"
};
