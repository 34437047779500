import React, { FC } from "react";
import Badge from "../../../../../common/adapters/primaries/components/Badge/Badge";
import { Company } from "../../../../domain/entities/company";
import i18n from "../../../../../common/configuration/i18n/i18n";
import { Link } from "react-router-dom";

interface Props {
    company: Company;
}

const CompanyName: FC<Props> = ({company}) => {
    return (
        <div className="listingSection__wrap">
            <div className="flex justify-between items-center">
                <div className=" !space-y-6">
                    <div className="flex justify-between items-center">
                        <Badge name={company.category}/>
                    </div>

                    <h1 className="text-2xl sm:text-3xl lg:text-4xl font-semibold">
                        {company.name}
                    </h1>

                    <div className="flex items-center space-x-4">
                        <span>
                            <i className="las la-user"></i>
                            <span className="ml-2 text-sm text-neutral-700">
                                {company.followersCount}{" "}
                                {i18n.t("company.followers")}
                            </span>
                        </span>

                        <span>·</span>

                        <span>
                            <i className="las la-map-marker-alt"></i>
                            <span className="ml-2 text-sm text-neutral-700">
                                {company.address.city}
                            </span>
                        </span>

                        {company.getBackLink() && (
                            <div>
                                <span className="mr-4">·</span>

                                <span>
                                    <i className="la la-globe-africa"></i>
                                    <span className="ml-2 text-sm text-neutral-700 hover:text-primary-6000">
                                        <Link to={company.getBackLink() as string}>
                                            {company.getBackLink()?.replace('http://', '').replace('https://', '')}
                                        </Link>
                                    </span>
                                </span>
                            </div>
                        )}
                    </div>
                </div>

                <img className={"block max-h-28 rounded"}
                     src={company.profileImageUrl.replace('dev.', '')}
                     alt={company.name}/>
            </div>
        </div>
    );
};

export default CompanyName;
