import { Epic, ofType, StateObservable } from 'redux-observable';
import { concatMap, Observable, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { LOAD_COMPANY_DETAILS, LoadCompanyDetailsAction } from "./actionTypes";
import { AppState } from "../../../redux-configuration/AppState";
import { CompanyService } from "../../domain/gateway/company.service";
import { Company } from "../../domain/entities/company";
import { loadCompanyDetailsFailed, loadCompanyDetailsSucceeded } from "./actions";
import { loadUpcomingInstantsByCompanyId } from "../../../instant/usecases/loadUpcomingInstantsByCompanyId/actions";
import { loadLastCreatedCompanies } from "../lastCreatedCompanies/actions";

export const loadCompanyDetailsEpic: Epic = (action$: Observable<LoadCompanyDetailsAction>,
                                             store: StateObservable<AppState>,
                                             {companyService}: { companyService: CompanyService }) =>
    action$.pipe(
        ofType(LOAD_COMPANY_DETAILS),
        switchMap((action: { payload: string }) => companyService.getCompanyDetails(action.payload)
            .pipe(
                concatMap((company: Company) => [
                    loadCompanyDetailsSucceeded(company),
                    loadUpcomingInstantsByCompanyId(action.payload),
                    loadLastCreatedCompanies(+company.address.zipCode)
                ]),
                catchError(err => of(loadCompanyDetailsFailed(err)))
            )
        )
    )
