import { Coordinates } from "../../common/domain/entities/Coordinates";

export class BusinessDistanceSpecifications {
    private static radiusOfTheEarthInKilometer = 6371
    private static radiusOfTheEarthInMeter: number = 6371 * 1000

    static calculateDistance(fromCoordinates: Coordinates, toCoordinates: Coordinates): string {
        return this.determineDistance(this.distanceCalculator(fromCoordinates, toCoordinates))
    }

    static calculateDistanceInMeter(fromCoordinates: Coordinates, toCoordinates: Coordinates): number {
        return this.roundDistanceInMeter(this.distanceCalculator(fromCoordinates, toCoordinates))
    }

    private static distanceCalculator(fromCoordinates: Coordinates, toCoordinates: Coordinates): number {
        return (Math.pow((fromCoordinates.longitude - toCoordinates.longitude), 2)
            + Math.pow((fromCoordinates.latitude - toCoordinates.latitude), 2))
    }

    private static determineDistance(distance: number): string {
        return this.isMoreThanOneKilometer(distance)
            ? this.formatAboveOneKilometer(distance)
            : this.formatBelowOneKilometer(distance)
    }

    private static isMoreThanOneKilometer(distance: number): boolean {
        return this.radiusOfTheEarthInKilometer * distance >= 1
    }

    private static formatAboveOneKilometer(distance: number): string {
        return Math.round(this.radiusOfTheEarthInKilometer * distance * 10) / 10 + 'km'
    }

    private static formatBelowOneKilometer(distanceInKilometer: number): string {
        return this.roundDistanceInMeter(distanceInKilometer) + 'm'
    }

    private static roundDistanceInMeter(distance: number): number {
        return Math.round(this.radiusOfTheEarthInMeter * distance)
    }
}
