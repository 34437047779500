import { Observable, throwError } from 'rxjs';
import { CompanyService } from "../../../domain/gateway/company.service";
import { Company } from "../../../domain/entities/company";
import { catchError, map } from "rxjs/operators";
import { CompanyDto, CompanyHeaderDTO } from "./dto/company.dto";
import { CompanyMapper } from "./mapper/mapper";
import { SecuredObservableAjaxHttpClient } from "../../../../common/adapters/secondaries/real/securedObservableAjaxHttpClient";
import { CompanyHeader } from "../../../domain/entities/companyHeader";
import { CompanyEmail } from "../../../domain/entities/companyEmail";

export class ApiCompanyService implements CompanyService {

    getCompanyDetails(companyId: string): Observable<Company> {
        const url = process.env.REACT_APP_API_URL + '/v1/company/settings/' + companyId
        return new SecuredObservableAjaxHttpClient().get<{ data: CompanyDto }>(url)
            .pipe(
                map((response: { data: CompanyDto }) => CompanyMapper.mapCompanyDTO(response.data)),
                catchError(err => throwError(err))
            )
    }

    getLastCreatedCompanies(zipCode: number): Observable<CompanyHeader[]> {
        const url = process.env.REACT_APP_API_URL + '/v1/relationships/company/latest?zipCode=' + zipCode
        return new SecuredObservableAjaxHttpClient().get<{ data: CompanyHeaderDTO[] }>(url)
            .pipe(
                map((response: { data: CompanyHeaderDTO[] }) => CompanyMapper.mapCompanyHeaderDTO(response.data)),
                catchError(err => throwError(err))
            )
    }

    sendEmailToCompany(email: CompanyEmail): Observable<void> {
        const url = process.env.REACT_APP_API_URL + '/v1/company/' + email.companyId + '/email'
        const body = new FormData();

        body.append('firstName', email.firstName);
        body.append('lastName', email.lastName);
        body.append('email', email.email);
        body.append('phoneNumber', email.phoneNumber);
        body.append('subject', email.subject);
        body.append('content', email.message);

        return new SecuredObservableAjaxHttpClient().post(url, body)
            .pipe(
                map(() => void 0),
                catchError(err => throwError(err))
            )
    }

}
