import React, { FC } from "react";
import { InstantHeader } from "../../../../domain/entities/instantHeader";
import { InstantTimeSpecification } from "../../../../domain/entities/specifications/instant.time.specification";

export interface Props {
    instant: InstantHeader;
    className?: string;
}

const InstantCard: FC<Props> = (props: Props) => {
    return (
        <div
            className={`nc-StayCard group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-2xl overflow-hidden will-change-transform hover:shadow-xl transition-shadow ${props.className}`}
            data-nc-id="StayCard">
            <div className={"p-3 space-y-2"}>
                <div className="space-y-2">
                    <img alt={props.instant.title} src={props.instant.picture} className={'w-full rounded-xl'}/>
                    <div className="flex items-center space-x-2">
                        <h2 className={"font-medium capitalize text-base"}>
                            <span className="line-clamp-1 ">{props.instant.title}</span>
                        </h2>
                    </div>
                    <div className="flex items-center text-neutral-500 dark:text-neutral-400 text-sm space-x-2">
                        <span className="" style={{
                            textOverflow   : 'ellipsis',
                            overflow: 'hidden',
                            display        : "-webkit-box",
                            lineClamp      : 3,
                            WebkitLineClamp: 3,
                            WebkitBoxOrient: "vertical"
                        }}>
                            {props.instant.description}
                        </span>
                    </div>
                </div>
                <div className={` rounded-br-xl rounded-tr-xl`} style={{
                    backgroundColor: determineTimeLeftColor(),
                    height         : 5,
                    width          : determineTimeLeftPercentage()
                }}>&nbsp;</div>
                <div className="flex justify-between items-center">
                      <span className="text-current font-semibold" style={{color: determineTimeLeftStatusColor()}}>
                          {determineTimeLeftStatus() + ' ' + props.instant.formattedTimeLeft()}
                      </span>
                    <span className="text-current font-semibold" style={{color: determineTimeLeftStatusColor()}}>
                          {props.instant.city}
                      </span>
                </div>
            </div>
        </div>
    );


    function determineTimeLeftColor(): string {
        return InstantTimeSpecification.timeLeftColor(props.instant.timeLeftInSecond(), props.instant.isStarted())
    }

    function determineTimeLeftPercentage(): string {
        return InstantTimeSpecification.timeLeftPercentage(props.instant.timeLeftInSecond()) + '%'
    }

    function determineTimeLeftStatusColor(): string {
        return InstantTimeSpecification.timeLeftColor(props.instant.timeLeftInSecond(), props.instant.isStarted()) === 'red' ? 'red' : '#999'
    }

    function determineTimeLeftStatus(): string {
        return InstantTimeSpecification.timeLeftStatus(props.instant.timeLeftInSecond(), props.instant.isStarted())
    }
};

export default InstantCard;
