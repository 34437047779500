import { CityHeader } from "../../../../domain/entities/CityHeader";
import { CityHeaderBuilder } from "../../../../domain/entities/CityHeaderBuilder";
import { CityDTO } from "../dto/city.DTO";

export class CityMapper {

    static mapDataToCities = (data: CityDTO[]): CityHeader[] => {
        const cities = [
            ...CityMapper.getParisCities(data),
            ...CityMapper.getOtherCities(data)
        ]
        return cities.map(cityDTO =>
            new CityHeaderBuilder()
                .withName(cityDTO.name)
                .withZipCode(cityDTO.zip)
                .build()
        )
    }

    private static getParisCities = (data: CityDTO[]): CityDTO[] => {
        return data.filter(city => city.zip < 75021 && city.zip > 75000)
            .sort((a, b) => (a.zip > b.zip) ? 1 : -1)
    }

    private static getOtherCities = (data: CityDTO[]): CityDTO[] => {
        return data.filter(city => city.zip > 75020 || city.zip < 75000)
            .sort((a, b) => (a.name > b.name) ? 1 : -1)
    }
}
