import { createLogger } from 'redux-logger'
import { type AppState } from './AppState'
import { epicsMiddleware, rootEpics } from './epicsMiddleware.redux'
import { configureStore } from "@reduxjs/toolkit";
import { ConfigureStoreOptions } from "@reduxjs/toolkit/src/configureStore";
import { reduxReducers } from "./reducers.redux";
import { ToolkitStore } from "@reduxjs/toolkit/dist/configureStore";

export const reduxStore = (): ToolkitStore<AppState> => {

    const logger = createLogger({
        collapsed: true,
        level    : 'info'
    })

    const options: ConfigureStoreOptions = {
        reducer   : reduxReducers,
        middleware: [epicsMiddleware, logger],
        devTools  : process.env.NODE_ENV === 'development',
    }
    const store: ToolkitStore = configureStore(options)

    epicsMiddleware.run(rootEpics)
    return store
}
