import React, { useEffect } from "react";
import VerticalInstantItem from "./verticalInstant.item";
import Heading from "../../../../../../common/adapters/primaries/components/Heading/Heading";
import { InstantHeader } from "../../../../../domain/entities/instantHeader";
import { AppEventBuilder } from "../../../../../../common/domain/entities/logging/appEvent.builder";
import { AppEventsTypes } from "../../../../../../common/domain/appEvents.Type";
import { logEvent } from "../../../../../../common/usecases/logging/logging.action";
import { Dispatch } from "redux";
import { LogEventAction } from "../../../../../../common/usecases/logging/analytics.types";
import { useDispatch } from "react-redux";

interface Props {
    title: string;
    subTitle: string;
    instants: InstantHeader[];
}

const VerticalInstantsListing = (props: Props): JSX.Element => {

    const dispatch: Dispatch<LogEventAction> = useDispatch()

    useEffect(() => {
        const instantPreviewEvent = (new AppEventBuilder())
            .withNamespace(AppEventsTypes.namespace.pro_instant)
            .withAction(AppEventsTypes.action.preview)
        props.instants.map((item) => {
            instantPreviewEvent.withReference({itemtype: AppEventsTypes.itemType.instant, id: item.id})
        })
        dispatch(logEvent(instantPreviewEvent.build()))
    }, [])

    return (
        <div className="nc-PageHome3 relative overflow-hidden">
            <div className="container relative">
                <Heading desc={props.subTitle}
                         hasNextPrev={false}
                         isCenter={true}
                         className={'mt-28'}>
                    {props.title}
                </Heading>

                <div className="relative py-16">
                    <div className={`grid gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4`}>
                        {props.instants.map((instant) => <VerticalInstantItem key={instant.id} instant={instant}/>)}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default VerticalInstantsListing;
