import { InstantTypes, TypedCategoryType } from "./types/InstantTypes";
import { ApplicationContext } from "../../../common/configuration/application.context";
import { Coordinates } from "../../../common/domain/entities/Coordinates";

const moment = ApplicationContext.getInstance().momentJs()

export abstract class Instant {

    protected constructor(protected _id: string,
                          protected _title: string,
                          protected _description: string,
                          protected _category: InstantTypes,
                          protected _latitude: number,
                          protected _longitude: number,
                          protected _delay: string,
                          protected _activationStartDate: Date,
                          protected _activationEndDate: Date,
                          protected _originalStartDate: Date,
                          protected _businessId: string,
    ) {
    }

    get id(): string {
        return this._id
    }

    get title(): string {
        return this._title
    }

    get description(): string {
        return this._description
    }

    get category(): InstantTypes {
        return this._category;
    }

    get latitude(): number {
        return this._latitude
    }

    get longitude(): number {
        return this._longitude
    }

    get coordinate(): Coordinates {
        return {latitude: this._latitude, longitude: this._longitude}
    }

    get originalStartDate(): Date {
        return this._originalStartDate;
    }

    get dateStart(): Date {
        return this._activationStartDate
    }

    get dateEnd(): Date {
        return this._activationEndDate
    }

    get businessId(): string {
        return this._businessId;
    }


    isStarted(): boolean {
        return moment(this._originalStartDate).diff(moment(), 'seconds') <= 0
    }

    timeLeftInSecond(): number {
        if (this.isStarted())
            return moment(this._activationEndDate).diff(moment(), 'seconds') + 1
        else
            return moment(this._originalStartDate).diff(moment(), 'seconds') + 1
    }

    isInTheseCategories(categories: TypedCategoryType): boolean {
        return categories.categories.includes(this._category)
    }
}
