import React from "react";

const Footer: React.FC = () => {
    return (
        <div className="nc-Footer relative py-5 border-t border-neutral-200 dark:border-neutral-700">
            <p className="text-center">VivezIci © 2023, Tous droits réservés</p>
        </div>
    );
};

export default Footer;
