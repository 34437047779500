import { ApplicationContext } from "../../../../../common/configuration/application.context";

const moment = ApplicationContext.getInstance().momentJs()

export class TimeHelpers {

    static isTodayInstant(timeLeft: number): boolean {
        return moment().add(timeLeft, 'seconds').isSame(moment(), 'date')
    }

    static isTomorrowInstant(timeLeft: number): boolean {
        return moment().add(timeLeft, 'seconds').isSame(moment().add(1, 'days'), 'date')
    }

    static isThisWeekInstant(timeLeft: number): boolean {
        return moment().add(timeLeft, 'seconds').isBetween(moment().add(1, 'days'), moment().add(6, 'days'))
    }
}
