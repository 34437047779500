import { TimeHelpers } from "./helpers/Time.helpers";
import { TimeLeftHelpers } from "./helpers/timeLeft.helpers";
import { TimeLeftColors } from "./helpers/timeLeft.colors";
import { TimeLeftStatus } from "./helpers/timeLeft.status";
import { TimeLeftPercentage } from "./helpers/timeLeft.percentage";
import { ApplicationContext } from "../../../../common/configuration/application.context";
import i18n from "../../../../common/configuration/i18n/i18n";

const moment = ApplicationContext.getInstance().momentJs()

export class InstantTimeSpecification {

    private static formatUpcomingTimeLeft(timeLeft: number): string {
        return moment().add(timeLeft, 'seconds').format('HH') + 'h' + moment().add(timeLeft, 'seconds').format('mm')
    }

    static timeLeftColor(timeLeft: number, isStarted: boolean, colorsTab: { [key: number]: string } = {}): string {
        return TimeLeftColors.findTimeLeftColor(timeLeft, isStarted, colorsTab)
    }

    static formatTimeLeft(timeLeft: number): string {
        return TimeHelpers.isTodayInstant(timeLeft) ?
            InstantTimeSpecification.formatTodayTimeLeft(timeLeft) : this.formatUpcomingTimeLeft(timeLeft)
    }

    static formatTimeLeftInMinutes(timeLeft: number): string {
        return TimeHelpers.isTodayInstant(timeLeft) ?
            TimeLeftHelpers.HumanizeTimeLeftInMinutes(timeLeft) : this.formatUpcomingTimeLeft(timeLeft)
    }

    static timeLeftStatus(timeLeft: number, isStarted: boolean): string {
        return TimeLeftStatus.findTimeLeftStatus(timeLeft, isStarted)
    }

    static timeLeftPercentage(timeLeft: number): number {
        return TimeLeftPercentage.calculateTimeLeftPercentage(timeLeft)
    }

    private static formatTodayTimeLeft(timeLeft: number): string {
        return TimeLeftHelpers.HumanizeTimeLeft(timeLeft)
    }

    static humanizeInstantSchedule(startDate: Date, endDate: Date): string {
        const date = moment(startDate).format('dddd D MMMM')
        const startHour = moment(startDate).format('H')
        const startMinute = moment(startDate).format('mm')
        const endHour = moment(endDate).format('H')
        const endMinute = moment(endDate).format('mm')

        return i18n.t('instant.instant_schedule', {
            date     : date,
            startHour: startHour + 'h' + startMinute,
            endHour  : endHour + 'h' + endMinute
        })
    }
}
