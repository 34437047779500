import { InstantDetails } from './instantDetails'
import { InstantTypes } from "./types/InstantTypes";
import { ActivationDayType } from "./types/timeType";

export class InstantDetailsBuilder {

    protected _id!: string
    protected _title!: string
    protected _city!: string
    protected _address!: string
    protected _zipCode!: number
    protected _imagesURL!: string[]
    protected _category!: InstantTypes
    protected _description!: string
    protected _shortDescription!: string
    protected _latitude!: number
    protected _longitude!: number
    protected _delay!: string
    protected _activationDayType!: ActivationDayType
    protected _originalStartDate!: Date
    protected _activationEndDate!: Date
    protected _activationStartDate!: Date
    protected _businessId!: string

    withId(value: string): InstantDetailsBuilder {
        this._id = value
        return this
    }

    withTitle(value: string): InstantDetailsBuilder {
        this._title = value
        return this
    }

    withImagesURL(value: string[]): InstantDetailsBuilder {
        this._imagesURL = value
        return this
    }

    withCategory(value: InstantTypes): InstantDetailsBuilder {
        this._category = value
        return this
    }

    withDescription(value: string): InstantDetailsBuilder {
        this._description = value
        return this
    }

    withAddress(value: string): InstantDetailsBuilder {
        this._address = value
        return this
    }

    withCity(value: string): InstantDetailsBuilder {
        this._city = value
        return this
    }

    withZipCode(value: number): InstantDetailsBuilder {
        this._zipCode = value
        return this
    }

    withLatitude(value: number): InstantDetailsBuilder {
        this._latitude = value
        return this
    }

    withLongitude(value: number): InstantDetailsBuilder {
        this._longitude = value
        return this
    }

    withDateStart(value: Date): InstantDetailsBuilder {
        this._activationStartDate = value
        return this
    }

    withDateEnd(value: Date): InstantDetailsBuilder {
        this._activationEndDate = value
        return this
    }

    withActivationDayType(value: ActivationDayType): InstantDetailsBuilder {
        this._activationDayType = value
        return this
    }

    withDateStartOriginal(value: Date): InstantDetailsBuilder {
        this._originalStartDate = value
        return this
    }

    withDelay(value: string): InstantDetailsBuilder {
        this._delay = value
        return this
    }

    withShortDescription(value: string): InstantDetailsBuilder {
        this._shortDescription = value
        return this
    }

    withBusinessId(value: string): InstantDetailsBuilder {
        this._businessId = value
        return this
    }

    build(): InstantDetails {
        return new InstantDetails(
            this._id,
            this._title,
            this._city,
            this._address,
            this._zipCode,
            this._imagesURL,
            this._description,
            this._shortDescription,
            this._category,
            this._latitude,
            this._longitude,
            this._activationDayType,
            this._activationStartDate,
            this._activationEndDate,
            this._delay,
            this._originalStartDate,
            this._businessId
        )
    }
}
