import React, { FC, useEffect } from "react";
import { Company } from "../../domain/entities/company";
import { useParams } from "react-router-dom";
import MobileContactButton from "./components/contact/mobileContact.button";
import CompanyDetailsHeader from "./components/details/company.header";
import CompanyName from "./components/details/company.name";
import CompanyDescription from "./components/details/company.description";
import CompanyOpeningTime from "./components/details/company.openingTime";
import CompanyAddressSection from "./components/details/company.address";
import CompanyContactSection from "./components/contact/companyContact.section";
import OtherCompaniesListing from "./components/otherCompanies/otherCompanies.listing";
import { InstantHeader } from "../../../instant/domain/entities/instantHeader";
import { CompanyHeader } from "../../domain/entities/companyHeader";
import i18n from "../../../common/configuration/i18n/i18n";
import { AppEventBuilder } from "../../../common/domain/entities/logging/appEvent.builder";
import { AppEventsTypes } from "../../../common/domain/appEvents.Type";
import { logEvent } from "../../../common/usecases/logging/logging.action";
import { Dispatch } from "redux";
import { LogEventAction } from "../../../common/usecases/logging/analytics.types";
import { useDispatch } from "react-redux";
import { CompanyEmail } from "../../domain/entities/companyEmail";
import { Helmet } from "react-helmet";
import Loading from "../../../common/adapters/primaries/components/Loading/Loading";
import HorizontalInstantsListing from "../../../instant/adapters/primaries/components/list/horizontal/horizontalInstants.listing";

export interface Props {
    company: Company | undefined;
    loading: boolean;
    instants: InstantHeader[];
    otherCompanies: CompanyHeader[] | undefined;
    loadCompanyDetails: (companyId: string) => void;
    sendEmailToCompany: (email: CompanyEmail) => void;
}

const CompanyDetailsContainer: FC<Props> = (props: Props) => {
    const params = useParams();
    const dispatch: Dispatch<LogEventAction> = useDispatch();

    useEffect(() => {
        if (params.companyId && props.company && params.companyId !== props.company.id) {
            props.loadCompanyDetails(params.companyId);
        }
    }, [params.companyId]);

    useEffect(() => {
        if (params.companyId) {
            props.loadCompanyDetails(params.companyId);
        }
    }, []);

    useEffect(() => {
        if (props.company) {
            const appEventBuilder = (new AppEventBuilder())
                .withNamespace(AppEventsTypes.namespace.pro_web)
                .withAction(AppEventsTypes.action.display)
                .withReference({itemtype: AppEventsTypes.itemType.business, id: props.company.id})
            dispatch(logEvent(appEventBuilder.build()))
        }
    }, [props.company?.id]);

    if (props.company) {
        return (
            <div className="ListingDetailPage">
                <Helmet>
                    <title>VivezIci | {props.company.name}</title>
                    <meta property="og:title" key="og:title" content={`Vivez ICI | ${props.company.name}`}/>
                    <meta property="og:url" key="og:url" content={`https://vivezici.fr${props.company.publicPath()}`}/>
                    <meta property="og:description" key="og:description" content={props.company.contacts.description}/>
                    <meta property="og:image" key="og:image" content={props.company.profileImageUrl}/>
                </Helmet>

                <div className="container ListingDetailPage__content">
                    <div className="nc-ListingStayDetailPage">
                        <CompanyDetailsHeader imageUrl={props.company.coverImageUrl.replace('dev.', '')}
                                              title={props.company.name}/>

                        <main className=" relative z-10 mt-11 flex flex-col lg:flex-row ">
                            <div className="w-full lg:w-3/5 xl:w-2/3 space-y-8 lg:space-y-10 lg:pr-10">
                                <CompanyName company={props.company}/>

                                <CompanyDescription description={props.company.contacts.description}/>

                                <CompanyOpeningTime openingHours={props.company.openingHours}/>

                                {props.instants.length > 0 && <HorizontalInstantsListing title={i18n.t("company.our_instants")}
                                                                                         instants={props.instants.slice(0, 4)}/>}
                                <CompanyAddressSection name={props.company.name}
                                                       address={props.company.address}/>
                            </div>

                            <CompanyContactSection/>
                        </main>
                    </div>
                </div>

                {props.otherCompanies && props.otherCompanies.length > 0 &&
                    <OtherCompaniesListing
                        otherCompanies={props.otherCompanies.filter(company => company.id !== props.company?.id)}
                        title={i18n.t("company.other_companies")}
                        subTitle={i18n.t("company.joined_us")}/>}

                <MobileContactButton/>
            </div>
        );
    }
    return <Loading/>;
};

export default CompanyDetailsContainer;
