import { Epic, ofType, StateObservable } from 'redux-observable'
import { catchError, mergeMap, switchMap, concatMap } from 'rxjs/operators'
import { InstantsLoader } from "../../domain/gateways/Instants.loader";
import { Observable, of } from "rxjs";
import { AppState } from "../../../redux-configuration/AppState";
import { InstantDetails } from "../../domain/entities/instantDetails";
import { LOAD_INSTANT_DETAILS, LoadInstantDetailsAction } from "./actionTypes";
import { loadInstantDetailsFailed, loadInstantDetailsSucceeded } from "./actions";
import { CompanyService } from "../../../company/domain/gateway/company.service";
import { Company } from "../../../company/domain/entities/company";
import { loadUpcomingInstantsByCompanyId } from "../loadUpcomingInstantsByCompanyId/actions";

export const loadInstantDetailsEpic: Epic = (action$: Observable<LoadInstantDetailsAction>,
                                             store: StateObservable<AppState>,
                                             {instantsLoader, companyService}:
                                                 {
                                                     instantsLoader: InstantsLoader;
                                                     companyService: CompanyService;
                                                 }) =>
    action$.pipe(
        ofType(LOAD_INSTANT_DETAILS),
        switchMap(
            action => instantsLoader.loadInstantDetails(action.payload)
                .pipe(
                    mergeMap((instant: InstantDetails) => companyService.getCompanyDetails(instant.businessId)
                        .pipe(
                            concatMap((company: Company) => [
                                loadInstantDetailsSucceeded(instant, company),
                                loadUpcomingInstantsByCompanyId(instant.businessId)
                            ]),
                            catchError(error => of(loadInstantDetailsFailed(error)))
                        )
                    )
                )
        )
    )
