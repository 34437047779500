import { combineReducers } from 'redux'
import { InstantsState } from "./instant.states";
import { instantsListingReducer } from "../usecases/instantslisting/instantsListing.reducers";
import { setInstantCategoriesReducer } from "../usecases/setInstantCategories/reducer";
import { loadUpcomingInstantsByCompanyIdReducer } from "../usecases/loadUpcomingInstantsByCompanyId/reducer";
import { loadInstantDetailsReducer } from "../usecases/instantDetails/reducer";

export const instantsRootReducer = combineReducers<InstantsState>({
    listing                : instantsListingReducer,
    details                : loadInstantDetailsReducer,
    categories             : setInstantCategoriesReducer,
    companyUpcomingInstants: loadUpcomingInstantsByCompanyIdReducer
})
