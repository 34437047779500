import React from "react";
import MobileContactModal from "./mobileContact.modal";
import ButtonPrimary from "../../../../../common/adapters/primaries/components/Button/ButtonPrimary";

const MobileContactButton = () => {

    return (
        <div className="block lg:hidden fixed bottom-5 right-5 z-40">
            <MobileContactModal
                renderChildren={({openModal}) => (
                    <ButtonPrimary sizeClass="p-3 rounded-full" className={'!text-3xl'}
                        onClick={openModal}>
                        <i className="las la-envelope"></i>
                    </ButtonPrimary>
                )}
            />
        </div>
    );
};

export default MobileContactButton;
