import React, { useEffect } from "react";
import HorizontalInstantItem from "./horizontalInstant.item";
import { InstantHeader } from "../../../../../domain/entities/instantHeader";
import { AppEventBuilder } from "../../../../../../common/domain/entities/logging/appEvent.builder";
import { AppEventsTypes } from "../../../../../../common/domain/appEvents.Type";
import { logEvent } from "../../../../../../common/usecases/logging/logging.action";
import { Dispatch } from "redux";
import { LogEventAction } from "../../../../../../common/usecases/logging/analytics.types";
import { useDispatch } from "react-redux";

interface Props {
    title: string;
    instants: InstantHeader[];
}

const HorizontalInstantsListing = (props: Props): JSX.Element => {

    const dispatch: Dispatch<LogEventAction> = useDispatch()

    useEffect(() => {
        const instantPreviewEvent = (new AppEventBuilder())
            .withNamespace(AppEventsTypes.namespace.pro_instant)
            .withAction(AppEventsTypes.action.preview)
        props.instants.map((item) => {
            instantPreviewEvent.withReference({itemtype: AppEventsTypes.itemType.instant, id: item.id})
        })
        dispatch(logEvent(instantPreviewEvent.build()))
    }, [])

    return (
        <div className="listingSection__wrap">
            <div>
                <h3 className="text-2xl font-semibold">
                    {props.title}
                </h3>
            </div>

            <div className="w-14 border-b border-neutral-200"></div>

            <div className="grid grid-cols-1 gap-8">
                {props.instants.map((instant) => <HorizontalInstantItem key={instant.id} instant={instant}/>)}
            </div>
        </div>
    );
}

export default HorizontalInstantsListing;
