import { InstantHeader } from '../../../../domain/entities/instantHeader'
import { InstantHeaderBuilder } from '../../../../domain/entities/instantHeader.builder'
import { InstantHeaderDTO } from '../dto/InstantHeaderDTO'
import { InstantTypes } from "../../../../domain/entities/types/InstantTypes";
import { ApplicationContext } from "../../../../../common/configuration/application.context";
import { ActivationDayType, DelayType } from "../../../../domain/entities/types/timeType";
import { InstantDetailsBuilder } from "../../../../domain/entities/instantDetails.builder";
import { InstantDetailsDTO } from "../dto/InstantDetailsDTO";
import { InstantDetails } from "../../../../domain/entities/instantDetails";

const moment = ApplicationContext.getInstance().momentJs()

export class InstantMapper {

    static mapToInstantHeader(remoteInstant: InstantHeaderDTO): InstantHeader {
        let activationDayType: ActivationDayType = 'all_days'
        remoteInstant.activationTimes.map(activationTime => {
            activationDayType = activationTime.activeDayType
        })
        return new InstantHeaderBuilder()
            .withId(remoteInstant.id)
            .withTitle(remoteInstant.title)
            .withCategory(this.convertIdToCategory(remoteInstant.categoryId))
            .withPicture(remoteInstant.iconUrl)
            .withDescription(remoteInstant.shortDescription)
            .withLatitude(remoteInstant.latitude)
            .withLongitude(remoteInstant.longitude)
            .withDelay(this.convertToDomainDelay(remoteInstant.activationTimes[0].activationTimeType))
            .withOriginalStartDate(this.getTodayDate(
                remoteInstant.activationTimes[0].activeDayType,
                remoteInstant.activationTimes[0].startDateTime
            ))
            .withActivationStartDate(this.getTodayDate(
                remoteInstant.activationTimes[0].activeDayType,
                remoteInstant.activationTimes[0].activationStartDateTime
            ))
            .withActivationEndDate(this.getTodayDate(
                remoteInstant.activationTimes[0].activeDayType,
                remoteInstant.activationTimes[0].endDateTime
            ))
            .withBusinessId(remoteInstant.companyId)
            .withBusinessName(remoteInstant.companyName)
            .withZipCode(remoteInstant.zipCode)
            .withCity(remoteInstant.city)
            .withActivationDayType(activationDayType)
            .build()
    }

    static mapToInstantDetails(remoteInstant: InstantDetailsDTO): InstantDetails {
        const instantDetails = new InstantDetailsBuilder()
            .withId(remoteInstant.id)
            .withTitle(remoteInstant.title)
            .withAddress(remoteInstant.streetAddress)
            .withDescription(remoteInstant.longDescription)
            .withShortDescription(remoteInstant.shortDescription)
            .withImagesURL([remoteInstant.pictureUrl])
            .withLatitude(remoteInstant.latitude)
            .withLongitude(remoteInstant.longitude)
            .withCategory(this.convertIdToCategory(remoteInstant.categoryId))
            .withBusinessId(remoteInstant.companyId)
            .withCity(remoteInstant.city)
            .withZipCode(remoteInstant.zipCode)

        if (remoteInstant.activationTimes.length > 0) {
            instantDetails.withDelay(this.convertToDomainDelay(remoteInstant.activationTimes[0].activationTimeType))
                .withDateStartOriginal(this.getDate(remoteInstant.activationTimes[0].startDateTime))
                .withDateStart(this.getDate(remoteInstant.activationTimes[0].activationStartDateTime))
                .withDateEnd(this.getDate(remoteInstant.activationTimes[0].endDateTime))
                .withActivationDayType(remoteInstant.activationTimes[0].activeDayType)
        }

        return instantDetails.build()
    }


    public static getTodayDate(activeDayType: string, dateTime: string): Date {
        if (activeDayType === 'fixed')
            return InstantMapper.getDate(dateTime)
        else {
            const time = moment(dateTime, 'YYYY-MM-DDTHH:mm:ss').format('HH:mm:ss')
            const dataTimeString = moment().format('YYYY-MM-DDT') + time
            return moment(dataTimeString, 'YYYY-MM-DDTHH:mm:ss').toDate()
        }
    }

    public static getDate(dateTime: string): Date {
        return moment(dateTime, 'YYYY-MM-DDTHH:mm:ss').toDate()
    }

    private static convertToDomainDelay(activationTimeType: 'during_instant' | 'just_before_and_during_instant'): DelayType {
        if (activationTimeType === 'during_instant')
            return 'DURING'
        else
            return 'DURING_AND_BEFORE_TWO_HOUR'
    }

    private static convertIdToCategory(id: number): InstantTypes {
        const categories: { [key: number]: InstantTypes } = {
            1 : 'cultural',
            2 : 'sportive',
            3 : 'discovery',
            4 : 'gourmand',
            5 : 'festive',
            6 : 'heart',
            7 : 'info',
            8 : 'offer',
            9 : 'gift',
            10: 'musical'
        }
        return categories[id]
    }
}
