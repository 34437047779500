import {
    LOAD_INSTANT_DETAILS,
    LOAD_INSTANT_DETAILS_FAILED,
    LOAD_INSTANT_DETAILS_SUCCEEDED, LoadInstantDetailsActionTypes
} from "./actionTypes";
import { InstantDetailsState } from "../../configuration/instant.states";

const initialInstantDetailsState: InstantDetailsState = {
    isLoading: false,
    instant  : undefined,
    company  : undefined,
    error    : undefined
}

export const loadInstantDetailsReducer = (state = initialInstantDetailsState, action: LoadInstantDetailsActionTypes): InstantDetailsState => {
    switch (action.type) {
        case LOAD_INSTANT_DETAILS:
            return {
                isLoading: true,
                instant  : undefined,
                company  : undefined,
                error    : undefined
            }
        case LOAD_INSTANT_DETAILS_SUCCEEDED:
            return {
                isLoading: false,
                instant  : action.payload.instant,
                company  : action.payload.company,
                error    : undefined,
            }
        case LOAD_INSTANT_DETAILS_FAILED:
            return {
                isLoading: false,
                instant  : undefined,
                company  : undefined,
                error    : action.payload
            }
        default :
            return state
    }
}
