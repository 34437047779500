import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { Epic, ofType, StateObservable } from "redux-observable";
import { AppState } from "../../../redux-configuration/AppState";
import { GET_CITIES_LIST, GetCitiesListAction } from "./types";
import { getCitiesListFailed, getCitiesListSucceeded } from "./actions";
import { CityService } from "../../domain/gateway/cityService";


export const getCitiesListEpic: Epic = (action$: Observable<GetCitiesListAction>, store: StateObservable<AppState>, {cityService}: {
    cityService: CityService
}) =>
    action$.pipe(
        ofType(GET_CITIES_LIST),
        switchMap(() => cityService.loadCities()
            .pipe(
                map((cities) => getCitiesListSucceeded(cities)),
                catchError(err => of(getCitiesListFailed(err)))
            )
        )
    )
