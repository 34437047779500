export class FormValidation {
    static validateEmail(email: string): boolean {
        const regex = new RegExp(['^(([^<>()[\\]\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\.,;:\\s@"]+)*)',
            '|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.',
            '[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+',
            '[a-zA-Z]{2,}))$'].join(''));

        return regex.test(email.toLowerCase())
    }

    static validatePhoneNumber(phoneNumber: string): boolean {
        const regex = /^(\+33|0033|0)[1-9][0-9]{8}$/
        return regex.test(phoneNumber)
    }
}
