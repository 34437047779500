import { Coordinates } from "../Coordinates";

export class DistanceUtils {
    private static radiusOfTheEarthInMeter: number = 6371 * 1000

    public static calculateDistanceInMeter(fromCoordinates: Coordinates, toCoordinates: Coordinates): number {
        return this.roundDistanceInMeter(this.distanceCalculator(fromCoordinates, toCoordinates))
    }

    private static distanceCalculator(fromCoordinates: Coordinates, toCoordinates: Coordinates): number {
        const latitudeDelta = this.deg2rad(toCoordinates.latitude - fromCoordinates.latitude)
        const longitudeDelta = this.deg2rad(toCoordinates.longitude - fromCoordinates.longitude)

        const a = Math.sin(latitudeDelta / 2) *
            Math.sin(latitudeDelta / 2) +
            Math.cos(this.deg2rad(fromCoordinates.latitude)) *
            Math.cos(this.deg2rad(toCoordinates.latitude)) *
            Math.sin(longitudeDelta / 2) *
            Math.sin(longitudeDelta / 2)
        return 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
    }

    private static roundDistanceInMeter(distance: number): number {
        return Math.round(this.radiusOfTheEarthInMeter * distance)
    }

    private static deg2rad(deg: number): number {
        return deg * (Math.PI / 180)
    }
}
