import React, { FC, useEffect, useMemo } from "react";
import BackgroundSection from "../../../../../common/adapters/primaries/components/BackgroundSection/BackgroundSection";
import { CompanyHeader } from "../../../../domain/entities/companyHeader";
import Heading from "../../../../../common/adapters/primaries/components/Heading/Heading";
import NextPrev from "../../../../../common/adapters/primaries/components/NextPrev/NextPrev";
import useNcId from "../../../../../common/utils/useNcId";
import Glide from "@glidejs/glide";
import OtherCompanyItem from "./company.item";
import { AppEventBuilder } from "../../../../../common/domain/entities/logging/appEvent.builder";
import { AppEventsTypes } from "../../../../../common/domain/appEvents.Type";
import { logEvent } from "../../../../../common/usecases/logging/logging.action";
import { Dispatch } from "redux";
import { LogEventAction } from "../../../../../common/usecases/logging/analytics.types";
import { useDispatch } from "react-redux";

export interface SectionSliderNewCategoriesProps {
    title: string;
    subTitle: string;
    otherCompanies: CompanyHeader[];
}

const OtherCompaniesListing: FC<SectionSliderNewCategoriesProps> = ({title, subTitle, otherCompanies}) => {
    const UNIQUE_CLASS = "SectionSliderNewCategories__ListingDetailPage" + useNcId();
    const dispatch: Dispatch<LogEventAction> = useDispatch();

    const displayedCompany: string[] = []


    const MY_GLIDEJS = useMemo(() => {
        const itemPerRow = 5
        return new Glide(`.${UNIQUE_CLASS}`, {
            perView    : itemPerRow,
            gap        : 32,
            bound      : true,
            breakpoints: {
                1280: {
                    perView: itemPerRow - 1,
                },
                1024: {
                    gap    : 20,
                    perView: itemPerRow - 1,
                },
                768 : {
                    gap    : 20,
                    perView: itemPerRow - 2,
                },
                640 : {
                    gap    : 20,
                    perView: itemPerRow - 3,
                },
                500 : {
                    gap    : 20,
                    perView: 1.3,
                },
            },
        });
    }, [UNIQUE_CLASS]);

    useEffect(() => {
        setTimeout(() => {
            MY_GLIDEJS.mount();
        }, 100);

        MY_GLIDEJS.on('mount.after', () => {
            glideMountedHandler();
        })

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        MY_GLIDEJS.on('run.after', ((move: { direction: string }) => {
            glideMoveHandler(move);
        }))
        return () => {
            MY_GLIDEJS.destroy()
        }
    }, [MY_GLIDEJS, UNIQUE_CLASS]);

    return (
        <div className="container py-20">
            <div className="relative py-16">
                <BackgroundSection/>

                <div className={'nc-SectionSliderNewCategories'}>
                    <div className={`${UNIQUE_CLASS} flow-root`}>
                        <Heading desc={subTitle}
                                 hasNextPrev={false}
                                 isCenter={true}>
                            {title}
                        </Heading>

                        <div className="glide__track" data-glide-el="track">
                            <ul className="glide__slides">
                                {otherCompanies.map((item, index) => (
                                    <li key={index} className={'glide__slide'}>
                                        <OtherCompanyItem company={item}/>
                                    </li>
                                ))}
                            </ul>
                        </div>

                        {otherCompanies.length > 5 && <NextPrev className="justify-center mt-16"/>}
                    </div>
                </div>
            </div>
        </div>
    );


    function glideMountedHandler() {
        otherCompanies.map((item, index) => {
            if (index < MY_GLIDEJS.settings.perView) {
                displayedCompany.push(item.id)
            }
        })
        buildAndLogEvent(displayedCompany)
    }

    function glideMoveHandler(move: { direction: string }) {
        // when move back to the ends of the slide
        if (MY_GLIDEJS.index === (otherCompanies.length - MY_GLIDEJS.settings.perView)) {
            const temporaryArray: string[] = []
            for (let i = (otherCompanies.length - 1); i > (otherCompanies.length - MY_GLIDEJS.settings.perView - 1); i--) {
                if (!displayedCompany.includes(otherCompanies[i].id)) {
                    displayedCompany.push(otherCompanies[i].id)
                    temporaryArray.push(otherCompanies[i].id)
                }
            }
            buildAndLogEvent(temporaryArray)
        } else if (move.direction === '>' && MY_GLIDEJS.index !== 0) {
            if (!displayedCompany.includes(otherCompanies[MY_GLIDEJS.index + MY_GLIDEJS.settings.perView - 1].id)) {
                displayedCompany.push(otherCompanies[MY_GLIDEJS.index + MY_GLIDEJS.settings.perView - 1].id)
                buildAndLogEvent(otherCompanies[MY_GLIDEJS.index + MY_GLIDEJS.settings.perView - 1].id)
            }
        } else if (move.direction === '<' && MY_GLIDEJS.index !== 0) {
            if (!displayedCompany.includes(otherCompanies[MY_GLIDEJS.index].id)) {
                buildAndLogEvent(otherCompanies[MY_GLIDEJS.index].id)
                displayedCompany.push(otherCompanies[MY_GLIDEJS.index].id)
            }
        }
    }

    function buildAndLogEvent(ids: string[] | string): void {
        const companyEvent = (new AppEventBuilder())
            .withNamespace(AppEventsTypes.namespace.pro_web)
            .withAction(AppEventsTypes.action.preview)

        if (Array.isArray(ids) && ids.length > 0) {
            ids.map((item) => {
                companyEvent.withReference({itemtype: AppEventsTypes.itemType.business, id: item})
            })
            dispatch(logEvent(companyEvent.build()))
        } else if (typeof ids === 'string') {
            companyEvent.withReference({itemtype: AppEventsTypes.itemType.business, id: ids})
            dispatch(logEvent(companyEvent.build()))
        }
    }
};

export default OtherCompaniesListing;
