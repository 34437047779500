import {
    GET_CITIES_LIST,
    GET_CITIES_LIST_FAILED,
    GET_CITIES_LIST_SUCCEEDED,
    GetCitiesListAction,
    GetCitiesListFailedAction,
    GetCitiesListSucceededAction
} from "./types";
import { CityHeader } from "../../domain/entities/CityHeader";

export const getCitiesList = (): GetCitiesListAction => ({
    type   : GET_CITIES_LIST
})
export const getCitiesListFailed = (error: string): GetCitiesListFailedAction => ({
    type   : GET_CITIES_LIST_FAILED,
    payload: error
})

export const getCitiesListSucceeded = (cities: CityHeader[]): GetCitiesListSucceededAction => ({
    type   : GET_CITIES_LIST_SUCCEEDED,
    payload: cities
})