import { InstantHeader } from "../../domain/entities/instantHeader";

export const LOAD_UPCOMING_INSTANTS_BY_COMPANY_ID = 'LOAD_UPCOMING_INSTANTS_BY_COMPANY_ID'
export const LOAD_UPCOMING_INSTANTS_BY_COMPANY_ID_SUCCEEDED = 'LOAD_UPCOMING_INSTANTS_BY_COMPANY_ID_SUCCEEDED'
export const LOAD_UPCOMING_INSTANTS_BY_COMPANY_ID_FAILED = 'LOAD_UPCOMING_INSTANTS_BY_COMPANY_ID_FAILED'

export interface LoadUpcomingInstantsByCompanyIdAction {
    type: typeof LOAD_UPCOMING_INSTANTS_BY_COMPANY_ID;
    payload: string;
}

export interface LoadUpcomingInstantsByCompanyIdSucceededAction {
    type: typeof LOAD_UPCOMING_INSTANTS_BY_COMPANY_ID_SUCCEEDED;
    payload: InstantHeader[];
}

export interface LoadUpcomingInstantsByCompanyIdFailedAction {
    type: typeof LOAD_UPCOMING_INSTANTS_BY_COMPANY_ID_FAILED;
    payload: string;
}

export type LoadUpcomingInstantsByCompanyIdActionTypes =
    LoadUpcomingInstantsByCompanyIdAction
    | LoadUpcomingInstantsByCompanyIdSucceededAction
    | LoadUpcomingInstantsByCompanyIdFailedAction
