import { CityService } from "../domain/gateway/cityService";
import { ApiCityService } from "../adapters/secondaries/real/apiCity.service";
import { EventLogger } from "../domain/gateway/event.logger";
import { ApiLogger } from "../adapters/secondaries/real/apilogger";

export class CommonContextDependenciesFactory {

    static getCityService(): CityService {
        switch (process.env.REACT_APP_ENV) {
            case 'prod':
            case 'dev':
            default:
                return new ApiCityService(process.env.REACT_APP_API_URL)
        }
    }

    static getEventLogger(): EventLogger {
        return new ApiLogger(process.env.REACT_APP_LOG_URL as string);
    }
}
