import { AppEvent, Reference } from "./AppEvent";

export class AppEventBuilder {

    protected _nameSpace: string
    protected _action: string
    protected _references: Reference[]

    constructor() {
        this._nameSpace = '';
        this._action = '';
        this._references = [];
    }

    withNamespace(namespace: string): AppEventBuilder {
        this._nameSpace = namespace
        return this
    }

    withAction(action: string): AppEventBuilder {
        this._action = action
        return this
    }

    withReference(reference: Reference): AppEventBuilder {
        this._references.push(reference)
        return this
    }

    withAllReferences(references: Reference[]): AppEventBuilder {
        this._references = references
        return this
    }

    build(): AppEvent {
        return new AppEvent(this._nameSpace, this._action, this._references)
    }
}
