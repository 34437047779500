import { InstantDetails } from '../../domain/entities/instantDetails';
import {
    LOAD_INSTANT_DETAILS, LOAD_INSTANT_DETAILS_FAILED, LOAD_INSTANT_DETAILS_SUCCEEDED, LoadInstantDetailsAction, LoadInstantDetailsFailedAction,
    LoadInstantDetailsSucceededAction
} from "./actionTypes";
import { Company } from "../../../company/domain/entities/company";

export const loadInstantDetails = (id: string): LoadInstantDetailsAction => ({
    type   : LOAD_INSTANT_DETAILS,
    payload: id
})

export const loadInstantDetailsSucceeded = (instant: InstantDetails, company: Company): LoadInstantDetailsSucceededAction => ({
    type   : LOAD_INSTANT_DETAILS_SUCCEEDED,
    payload: {instant, company}
})

export const loadInstantDetailsFailed = (error: string): LoadInstantDetailsFailedAction => ({
    type   : LOAD_INSTANT_DETAILS_FAILED,
    payload: error
})
