import { CompanyHeader } from "../../domain/entities/companyHeader";

export const LOAD_LAST_CREATED_COMPANIES = 'LOAD_LAST_CREATED_COMPANIES'
export const LOAD_LAST_CREATED_COMPANIES_SUCCEEDED = 'LOAD_LAST_CREATED_COMPANIES_SUCCEEDED'
export const LOAD_LAST_CREATED_COMPANIES_FAILED = 'LOAD_LAST_CREATED_COMPANIES_FAILED'

export interface LoadLastCreatedCompaniesAction {
    type: typeof LOAD_LAST_CREATED_COMPANIES;
    payload: number;
}

export interface LoadLastCreatedCompaniesSucceededAction {
    type: typeof LOAD_LAST_CREATED_COMPANIES_SUCCEEDED;
    payload: CompanyHeader[];
}

export interface LoadLastCreatedCompaniesFailedAction {
    type: typeof LOAD_LAST_CREATED_COMPANIES_FAILED;
    payload: string;
}

export type LoadLastCreatedCompaniesActionTypes =
    LoadLastCreatedCompaniesAction
    | LoadLastCreatedCompaniesSucceededAction
    | LoadLastCreatedCompaniesFailedAction
