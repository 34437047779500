import { Observable, of } from 'rxjs'
import { catchError, map } from 'rxjs/operators';
import { EventLogger } from "../../../domain/gateway/event.logger";
import { AppEvent } from "../../../domain/entities/logging/AppEvent";
import { ObservableAjaxHttpClient } from "./observableAjaxHttpClient";
import { LogEventDTO } from "./dto/logEvent.dto";
import { AppEventMapper } from "./mapper/appEvent.mapper";

export class ApiLogger implements EventLogger {

    constructor(logApi: string) {
        this.logApi = logApi;
    }

    readonly logApi: string;


    private headers: Record<string, string> = {
        'Content-Type': 'application/json'
    }

    log(appEvent: AppEvent): Observable<void> {
        const logEventDTO: LogEventDTO = AppEventMapper.convertToLogEventDTO(appEvent)

        const body = JSON.stringify(this.updateLogEventWithDeviceInfo(logEventDTO))
        return (new ObservableAjaxHttpClient()).post(this.logApi, body, this.headers)
            .pipe(
                map(() => of(void 0)),
                catchError(err => of(err))
            )
    }

    private updateLogEventWithDeviceInfo(logEventDTO: LogEventDTO): LogEventDTO {
        return {
            timestamp : logEventDTO.timestamp,
            namespace : logEventDTO.namespace,
            action    : logEventDTO.action,
            references: logEventDTO.references
        }
    }
}
