import { CompanyEmail } from "../../domain/entities/companyEmail";

export const SEND_EMAIL_TO_COMPANY = 'SEND_EMAIL_TO_COMPANY'
export const SEND_EMAIL_TO_COMPANY_SUCCEEDED = 'SEND_EMAIL_TO_COMPANY_SUCCEEDED'
export const SEND_EMAIL_TO_COMPANY_FAILED = 'SEND_EMAIL_TO_COMPANY_FAILED'

export interface SendEmailToCompanyAction {
    type: typeof SEND_EMAIL_TO_COMPANY;
    payload: CompanyEmail;
}

export interface SendEmailToCompanySucceededAction {
    type: typeof SEND_EMAIL_TO_COMPANY_SUCCEEDED;
}

export interface SendEmailToCompanyFailedAction {
    type: typeof SEND_EMAIL_TO_COMPANY_FAILED;
    payload: string;
}

export type SendEmailToCompanyActionTypes =
    SendEmailToCompanyAction
    | SendEmailToCompanySucceededAction
    | SendEmailToCompanyFailedAction
