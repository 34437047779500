import { connect } from 'react-redux'
import { Dispatch } from "redux";
import { LoadInstantDetailsAction } from "../../../usecases/instantDetails/actionTypes";
import { loadInstantDetails } from "../../../usecases/instantDetails/actions";
import { instantCompanySelector, instantDetailsSelector, loadInstantDetailsLoadingSelector } from "../../../usecases/instantDetails/selectors";
import { InstantDetails } from "../../../domain/entities/instantDetails";
import { Company } from "../../../../company/domain/entities/company";
import { AppState } from "../../../../redux-configuration/AppState";
import InstantDetailsContainer from "./instantDetails.container";
import { InstantHeader } from "../../../domain/entities/instantHeader";
import { companyUpcomingInstantsSelector } from "../../../usecases/loadUpcomingInstantsByCompanyId/selectors";

interface StateToPropsType {
    instant: InstantDetails | undefined;
    company: Company | undefined;
    otherInstants: InstantHeader[] | undefined;
    loading: boolean;
}

interface DispatchToPropsType {
    loadInstantDetails: (instantId: string) => void;
}

const mapStateToProps = (state: AppState): StateToPropsType => ({
    instant      : instantDetailsSelector(state),
    company      : instantCompanySelector(state),
    otherInstants: companyUpcomingInstantsSelector(state),
    loading      : loadInstantDetailsLoadingSelector(state)
})

const mapDispatchToProps = (dispatch: Dispatch): DispatchToPropsType => ({
    loadInstantDetails: (instantId: string): LoadInstantDetailsAction => dispatch(loadInstantDetails(instantId)),
})

export const InstantDetailsPage = connect(mapStateToProps, mapDispatchToProps)(InstantDetailsContainer)
