import { CompanyOpeningHours } from "./companyOpeningHours";
import { Company } from "./company";
import { CompanyContact } from "./companyContact";
import { CompanyAddress } from "./companyAddress";
import { CompanyClickCollect } from "./companyClickCollect";
import { CompanyMembership } from "./companyMembership";
import { ClickCollectPicture, OpeningHoursType } from "./types/company.types";

export class CompanyBuilder {
    protected _id!: string
    protected _name!: string
    protected _type!: string
    protected _category!: string
    protected _membership!: CompanyMembership
    protected _address!: string
    protected _zipcode!: string
    protected _city!: string
    protected _longitude!: string
    protected _latitude!: string

    protected _siren!: string
    protected _coverImage!: File
    protected _profileImage!: File
    protected _coverImageUrl!: string
    protected _profileImageUrl!: string

    protected _openingHours: CompanyOpeningHours[] = []

    protected _description!: string
    protected _website!: string
    protected _email!: string
    protected _phoneNumber!: string
    protected _followersCount!: number;

    protected _premiumSubdomain!: string;

    protected _activeClickCollect!: boolean
    protected _picturesClickCollect!: ClickCollectPicture[]
    protected _descriptionClickCollect!: string

    withId(value: string): CompanyBuilder {
        this._id = value
        return this
    }

    withName(value: string): CompanyBuilder {
        this._name = value
        return this
    }

    withType(value: string): CompanyBuilder {
        this._type = value
        return this
    }

    withCategory(value: string): CompanyBuilder {
        this._category = value
        return this
    }

    withMembership(value: CompanyMembership): CompanyBuilder {
        this._membership = value
        return this
    }

    withAddress(value: string): CompanyBuilder {
        this._address = value
        return this
    }

    withZipCode(value: string): CompanyBuilder {
        this._zipcode = value
        return this
    }

    withCity(value: string): CompanyBuilder {
        this._city = value
        return this
    }

    withLongitude(value: string): CompanyBuilder {
        this._longitude = value
        return this
    }

    withLatitude(value: string): CompanyBuilder {
        this._latitude = value
        return this
    }

    withCoverImage(value: File): CompanyBuilder {
        this._coverImage = value
        return this
    }

    withProfileImage(value: File): CompanyBuilder {
        this._profileImage = value
        return this
    }

    withCoverImageUrl(value: string): CompanyBuilder {
        this._coverImageUrl = value
        return this
    }

    withProfileImageUrl(value: string): CompanyBuilder {
        this._profileImageUrl = value
        return this
    }

    withOpeningHours(value: OpeningHoursType[]): CompanyBuilder {
        value.map(item => {
            this._openingHours.push(
                new CompanyOpeningHours(
                    item.id,
                    item.startTime,
                    item.endTime,
                    item.daytype,
                    item.isPaused
                )
            )
        })
        return this
    }

    withDescription(value: string): CompanyBuilder {
        this._description = value
        return this
    }

    withWebsite(value: string): CompanyBuilder {
        this._website = value
        return this
    }

    withEmail(value: string): CompanyBuilder {
        this._email = value
        return this
    }

    withPhoneNumber(value: string): CompanyBuilder {
        this._phoneNumber = value
        return this
    }

    withFollowersCount(value: number): CompanyBuilder {
        this._followersCount = value
        return this
    }

    withPremiumSubdomain(value: string): CompanyBuilder {
        this._premiumSubdomain = value
        return this
    }

    withDescriptionClickCollect(value: string): CompanyBuilder {
        this._descriptionClickCollect = value
        return this
    }

    withActiveClickCollect(value: boolean): CompanyBuilder {
        this._activeClickCollect = value
        return this
    }

    withPicturesClickCollect(value: ClickCollectPicture[]): CompanyBuilder {
        this._picturesClickCollect = value
        return this
    }

    build(): Company {
        const contact = new CompanyContact(
            this._description,
            this._website,
            this._email,
            this._phoneNumber
        )

        const address = new CompanyAddress(
            this._address,
            this._zipcode,
            this._latitude,
            this._longitude,
            this._city
        )

        const clickCollect = new CompanyClickCollect(this._activeClickCollect, this._descriptionClickCollect, this._picturesClickCollect
        )

        return new Company(
            this._id,
            this._name,
            this._type,
            this._category,
            this._membership,
            this._siren,
            this._coverImage,
            this._profileImage,
            this._coverImageUrl,
            this._profileImageUrl,
            this._followersCount,
            this._openingHours,
            contact,
            address,
            this._premiumSubdomain,
            clickCollect
        )
    }
}
