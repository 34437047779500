import React, { FC } from "react";
import { CompanyOpeningHours } from "../../../../domain/entities/companyOpeningHours";
import i18n from "../../../../../common/configuration/i18n/i18n";
import { CompanySpecifications } from "../../specifications";

interface Props {
    openingHours: CompanyOpeningHours[];
}

const CompanyOpeningTime: FC<Props> = ({openingHours}) => {
    return (
        <div className="listingSection__wrap">
            <div>
                <h3 className="text-2xl font-semibold">
                    {i18n.t("company.opening_hours")}
                </h3>
            </div>

            <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

            <div className="flow-root">
                <div className="text-sm sm:text-base text-neutral-6000 dark:text-neutral-300">
                    {CompanySpecifications.getCompanyOpeningDays(openingHours).map((time, index) => {
                        const className = index % 2 === 0 ? "bg-neutral-100 dark:bg-neutral-800" : "";

                        return (
                            <div key={index} className={"p-4 flex justify-between space-x-4 rounded-lg " + className}>
                                <span>{time.day}</span>
                                <span style={{whiteSpace: 'pre', lineHeight: '26px', textAlign: 'center'}}>{time.hour.join("\r\n")}</span>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    );
};

export default CompanyOpeningTime;
