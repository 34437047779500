import { SET_INSTANT_CATEGORIES, SetInstantCategoriesAction } from "./actionType";
import { InstantTypes, TypedCategoryType } from "../../domain/entities/types/InstantTypes";

const initialState: TypedCategoryType = {
    type      : "all",
    categories: ['cultural', 'sportive', 'discovery', 'gourmand', 'festive', 'heart', 'info', 'offer', 'gift', 'musical']
}

const allCat: InstantTypes[] = ['cultural', 'sportive', 'discovery', 'gourmand', 'festive', 'heart', 'info', 'offer', 'gift', 'musical']

export const setInstantCategoriesReducer = (state = initialState, action: SetInstantCategoriesAction): TypedCategoryType => {
    switch (action.type) {
        case SET_INSTANT_CATEGORIES:
            if (action.payload.categories.length === 0) {
                return {type: 'all', categories: allCat}
            }
            return action.payload
        default:
            return state
    }
}
