import { EVENT_LOGGED, LOG_EVENT } from './logging.action'
import { EventLoggedAction, LogEventAction } from "./analytics.types";
import { LoggingState } from "../../configuration/common.states";

const initialState: LoggingState = {
    isLogging: false,
    isLogged : false
}

export const loggingReducer = (state = initialState, action: LogEventAction | EventLoggedAction): LoggingState => {
    switch (action.type) {
        case LOG_EVENT:
            return {
                isLogging: true,
                isLogged : false
            }
        case EVENT_LOGGED:
            return {
                isLogging: false,
                isLogged : true
            }
        default :
            return state
    }
}
