import { CompanyOpeningHours } from "./companyOpeningHours";
import { CompanyContact } from "./companyContact";
import { CompanyAddress } from "./companyAddress";
import { CompanyClickCollect } from "./companyClickCollect";
import { CompanyMembership } from "./companyMembership";
import { sanitizeUrl } from "../../../common/utils/urlSanitizer";

export class Company {
    static FREEMIUM = 'freemium'
    static PREMIUM = 'premium'

    constructor(
        protected _id: string,
        protected _name: string,
        protected _type: string,
        protected _category: string,
        protected _membership: CompanyMembership,
        protected _siren: string,
        protected _coverImage: File,
        protected _profileImage: File,
        protected _coverImageUrl: string,
        protected _profileImageUrl: string,
        protected _followersCount: number,
        protected _openingHours: CompanyOpeningHours[],
        protected _contacts: CompanyContact,
        protected _address: CompanyAddress,
        protected _premiumSubdomain: string,
        protected _clickCollect: CompanyClickCollect
    ) {
    }

    get id(): string {
        return this._id
    }

    get name(): string {
        return this._name
    }

    get type(): string {
        return this._type
    }


    get category(): string {
        return this._category
    }


    get membership(): CompanyMembership {
        return this._membership;
    }

    getBackLink(): string | undefined {
        if (this._membership.type != 'freemium') {
            if (this._contacts.website !== '') {
                return this._contacts.website
            }
            if (this._premiumSubdomain !== '') {
                return `https://${this._premiumSubdomain}.vivezici.fr`
            }
            return undefined
        }
        return undefined
    }

    get address(): CompanyAddress {
        return this._address
    }

    get siren(): string {
        return this._siren
    }

    get coverImage(): File {
        return this._coverImage
    }

    get profileImage(): File {
        return this._profileImage
    }

    get coverImageUrl(): string {
        return this._coverImageUrl
    }

    get profileImageUrl(): string {
        return this._profileImageUrl
    }

    get followersCount(): number {
        return this._followersCount;
    }

    get openingHours(): CompanyOpeningHours[] {
        return this._openingHours
    }

    get contacts(): CompanyContact {
        return this._contacts
    }

    get premiumSubdomain(): string {
        return this._premiumSubdomain
    }

    get clickCollect(): CompanyClickCollect {
        return this._clickCollect;
    }


    publicPath(): string {
        return `/${sanitizeUrl(this._address.city.trim())}/${this._type}/${sanitizeUrl(this._category.trim())}/${sanitizeUrl(this._name.trim())}/${this._id}`
    }
}
