import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { InstantFrI18n } from "../../../instant/configuration/assets/instant.fr.i18n";
import { InstantEnI18n } from "../../../instant/configuration/assets/instant.en.i18n";
import { CompanyEnI18n } from "../../../company/configuration/assets/company.en.i18n";
import { CompanyFrI18n } from "../../../company/configuration/assets/company.fr.i18n";

i18n.use(LanguageDetector)
    .use(initReactI18next)
    .init({
        debug        : false,
        fallbackLng  : 'fr',
        interpolation: {
            escapeValue: false,
        },
        keySeparator : '.',
        resources    : {
            en: {
                translation: {
                    instant: InstantEnI18n,
                    company: CompanyEnI18n,
                }
            },
            fr: {
                translation: {
                    instant: InstantFrI18n,
                    company: CompanyFrI18n,
                }
            }
        }
    });

export default i18n;
