import { combineEpics, Epic } from 'redux-observable';
import { loadCompanyDetailsEpic } from "../usecases/loadCompanyDetails/epic";
import { loadLastCreatedCompaniesEpic } from "../usecases/lastCreatedCompanies/epic";
import { sendEmailToCompanyEpic } from "../usecases/sendEmailTocompany/epic";

export const companyRootEpic: Epic = combineEpics(
    loadCompanyDetailsEpic,
    loadLastCreatedCompaniesEpic,
    sendEmailToCompanyEpic
)
