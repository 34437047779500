export const InstantParameters = {
    mapDefaultCenter: {
        latitude : 48.8566,
        longitude: 2.3522
    },
    mapDefaultRegion: {
        lat: 48.8566,
        lng: 2.3522
    }
}
