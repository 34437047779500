import { avatarColors } from "./contants";
import React, { FC } from "react";

export interface AvatarProps {
    imgUrl: string;
    name: string;
    containerClassName?: string;
    sizeClass?: string;
    radius?: string;
    hasChecked?: boolean;
}

const Avatar: FC<AvatarProps> = ({
                                     imgUrl,
                                     name,
                                     containerClassName = "ring-1 ring-white dark:ring-neutral-900",
                                     sizeClass = "h-6 w-6 text-sm",
                                     radius = "rounded-full",
                                     hasChecked
                                 }) => {
    const _setBgColor = (name: string) => {
        const backgroundIndex = Math.floor(
            name.charCodeAt(0) % avatarColors.length
        );
        return avatarColors[backgroundIndex];
    };

    return (
        <div
            className={`wil-avatar relative flex-shrink-0 inline-flex items-center justify-center text-neutral-100 uppercase font-semibold shadow-inner ${radius} ${sizeClass} ${containerClassName}`}
            style={{backgroundColor: imgUrl ? undefined : _setBgColor(name)}}>
            <img className={`absolute inset-0 w-full h-full object-cover ${radius}`}
                 src={imgUrl}
                 alt={name}/>

            <span className="wil-avatar__name">{name[0]}</span>

            {hasChecked && (
                <span className={'bg-teal-500 rounded-full text-white text-xs flex items-center justify-center absolute w-4 h-4 top-1 right-0'}>
                    <i className="las la-check"></i>
                </span>
            )}
        </div>
    );
};

export default Avatar;
