import { CompanyUpcomingInstantsState } from "../../configuration/instant.states";
import {
    LOAD_UPCOMING_INSTANTS_BY_COMPANY_ID,
    LOAD_UPCOMING_INSTANTS_BY_COMPANY_ID_FAILED,
    LOAD_UPCOMING_INSTANTS_BY_COMPANY_ID_SUCCEEDED,
    LoadUpcomingInstantsByCompanyIdActionTypes
} from "./actionTypes";

const initialState: CompanyUpcomingInstantsState = {
    data     : [],
    isLoading: false,
    error    : null
}

export const loadUpcomingInstantsByCompanyIdReducer = (state = initialState, action: LoadUpcomingInstantsByCompanyIdActionTypes): CompanyUpcomingInstantsState => {
    switch (action.type) {
        case LOAD_UPCOMING_INSTANTS_BY_COMPANY_ID:
            return {
                data     : [],
                isLoading: true,
                error    : null
            }
        case LOAD_UPCOMING_INSTANTS_BY_COMPANY_ID_SUCCEEDED:
            return {
                data     : action.payload,
                isLoading: false,
                error    : null
            }
        case LOAD_UPCOMING_INSTANTS_BY_COMPANY_ID_FAILED:
            return {
                data     : [],
                isLoading: false,
                error    : action.payload
            }
        default:
            return state
    }
}
