import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import MainHeader from "../../../../common/adapters/primaries/components/Header/MainHeader";
import { useLocation } from "react-router-dom";

const OPTIONS = {
    root      : null,
    rootMargin: "0px",
    threshold : 1.0,
};
let OBSERVER: IntersectionObserver | null = null;

const SiteHeader = () => {
    const anchorRef = React.useRef<HTMLDivElement>(null);

    const [isTopOfPage, setIsTopOfPage] = React.useState(window.pageYOffset < 5);
    const location = useLocation();

    const intersectionCallback = (entries: IntersectionObserverEntry[]) => {
        entries.forEach((entry) => {
            setIsTopOfPage(entry.isIntersecting);
        });
    };

    useEffect(() => {
        OBSERVER = new IntersectionObserver(intersectionCallback, OPTIONS);
        anchorRef.current && OBSERVER.observe(anchorRef.current);
    }, [location.pathname]);

    const renderHeader = () => {
        const className = isTopOfPage ? "" : "shadow-sm dark:border-b dark:border-neutral-700";

        return <MainHeader className={className}
                           size={isTopOfPage ? 250 : 180}/>;
    };

    return (
        <>
            <Helmet>
                <title>Vivez ICI | En ce moment</title>
            </Helmet>

            {renderHeader()}

            <div ref={anchorRef} className="h-1 absolute invisible"></div>
        </>
    );
};

export default SiteHeader;
