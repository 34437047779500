import {
    LOAD_LAST_CREATED_COMPANIES,
    LOAD_LAST_CREATED_COMPANIES_FAILED,
    LOAD_LAST_CREATED_COMPANIES_SUCCEEDED,
    LoadLastCreatedCompaniesAction,
    LoadLastCreatedCompaniesFailedAction,
    LoadLastCreatedCompaniesSucceededAction
} from "./actionTypes";
import { CompanyHeader } from "../../domain/entities/companyHeader";

export const loadLastCreatedCompanies = (zipCode: number): LoadLastCreatedCompaniesAction => ({
    type   : LOAD_LAST_CREATED_COMPANIES,
    payload: zipCode
})

export const loadLastCreatedCompaniesSucceeded = (data: CompanyHeader[]): LoadLastCreatedCompaniesSucceededAction => ({
    type   : LOAD_LAST_CREATED_COMPANIES_SUCCEEDED,
    payload: data
})

export const loadLastCreatedCompaniesFailed = (error: string): LoadLastCreatedCompaniesFailedAction => ({
    type   : LOAD_LAST_CREATED_COMPANIES_FAILED,
    payload: error
})
