import React, { FC } from "react";
import { CompanyAddress } from "../../../../domain/entities/companyAddress";
import i18n from "../../../../../common/configuration/i18n/i18n";

interface Props {
    name: string;
    address: CompanyAddress;
}

const CompanyAddressSection: FC<Props> = ({name, address}) => {
    const searchAddress =
              name.replace(" ", "+") +
              "," +
              address.address.replace(" ", "+") +
              "," +
              address.city.replace(" ", "+") +
              "+France";

    return (
        <div className="listingSection__wrap">
            {/* HEADING */}
            <div>
                <h3 className="text-2xl font-semibold">
                    {i18n.t("company.address")}
                </h3>
                <span className="block mt-2 text-neutral-500">
                    {address.address}, {address.zipCode} {address.city},{" "}
                    {i18n.t("company.france")}
                </span>
            </div>

            <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"/>

            {/* MAP */}
            <div className="aspect-w-5 aspect-h-5 sm:aspect-h-3 ring-1 ring-black/10 rounded-xl z-0">
                <div className="rounded-xl overflow-hidden z-0">
                    <iframe title="x"
                            width="100%"
                            height="100%"
                            loading="lazy"
                            allowFullScreen
                            referrerPolicy="no-referrer-when-downgrade"
                            src={"https://www.google.com/maps/embed/v1/place?key=" + process.env.REACT_APP_MAP_KEY + "&q=" + searchAddress}
                    ></iframe>
                </div>
            </div>
        </div>
    );
};

export default CompanyAddressSection;
