import { GET_CITIES_LIST, GET_CITIES_LIST_FAILED, GET_CITIES_LIST_SUCCEEDED, GetCitiesListActionTypes } from "./types";
import { CityState } from "../../configuration/common.states";

const initialState: CityState = {
    isLoading: false,
    error    : undefined,
    data     : undefined
}
export const getCitiesListReducer = (state = initialState, action: GetCitiesListActionTypes): CityState => {
    switch (action.type) {
        case GET_CITIES_LIST:
            return {...state, isLoading: true}
        case GET_CITIES_LIST_FAILED:
            return {isLoading: false, error: action.payload, data: undefined}
        case GET_CITIES_LIST_SUCCEEDED:
            return {isLoading: false, error: undefined, data: action.payload}
        default:
            return state
    }
}
