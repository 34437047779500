import React, { FC } from "react";
import i18n from "../../../../../common/configuration/i18n/i18n";

interface Props {
    description: string;
}

const CompanyDescription: FC<Props> = ({description}) => {
    return (
        <div className="listingSection__wrap">
            <h2 className="text-2xl font-semibold">
                {i18n.t("company.about")}
            </h2>

            <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

            <div className="text-neutral-6000 dark:text-neutral-300 company-description">
                <div dangerouslySetInnerHTML={{__html: description}}/>
            </div>
        </div>
    );
};

export default CompanyDescription;
