import {
    LOAD_COMPANY_DETAILS,
    LOAD_COMPANY_DETAILS_FAILED,
    LOAD_COMPANY_DETAILS_SUCCEEDED,
    LoadCompanyDetailsAction, LoadCompanyDetailsFailedAction, LoadCompanyDetailsSucceededAction
} from "./actionTypes";
import { Company } from "../../domain/entities/company";

export const loadCompanyDetails = (companyId: string): LoadCompanyDetailsAction => ({
    type   : LOAD_COMPANY_DETAILS,
    payload: companyId
})

export const loadCompanyDetailsSucceeded = (company: Company): LoadCompanyDetailsSucceededAction => ({
    type   : LOAD_COMPANY_DETAILS_SUCCEEDED,
    payload: company
})

export const loadCompanyDetailsFailed = (error: string): LoadCompanyDetailsFailedAction => ({
    type   : LOAD_COMPANY_DETAILS_FAILED,
    payload: error
})
