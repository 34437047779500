import { HttpClient } from '../../../domain/gateway/httpClient';
import { from, Observable } from 'rxjs';
import axios from "axios";

export class SecuredObservableAjaxHttpClient implements HttpClient {
    readonly token: string;

    constructor() {
        if (process.env.REACT_APP_API_TOKEN === undefined) {
            throw new Error('api token should not be undefined')
        }
        this.token = process.env.REACT_APP_API_TOKEN;
    }

    get<R>(url: string, headers?: Record<string, string>): Observable<R> {
        const promise = new Promise<R>((resolve, reject) => {
            axios.get<R>(url, {
                headers: this.getHeaders(this.token, headers)
            })
                .then((rs) => resolve(rs.data))
                .catch((reason) => reject(reason))
        })
        return from(promise)
    }

    post<T>(url: string, body?: object, headers?: Record<string, string>): Observable<T> {
        const promise = new Promise<T>((resolve, reject) => {
            axios.post<T>(url, body, {
                headers: this.getHeaders(this.token, headers)
            })
                .then((rs) => resolve(rs.data))
                .catch((reason) => reject(reason))
        })
        return from(promise)
    }

    private getHeaders(token: string, headers?: Record<string, string>): Record<string, string> {
        return {
            ...headers,
            Authorization: `${token}`,
        }
    }
}
