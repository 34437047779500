import {
    LOAD_UPCOMING_INSTANTS_BY_COMPANY_ID,
    LOAD_UPCOMING_INSTANTS_BY_COMPANY_ID_FAILED,
    LOAD_UPCOMING_INSTANTS_BY_COMPANY_ID_SUCCEEDED,
    LoadUpcomingInstantsByCompanyIdAction,
    LoadUpcomingInstantsByCompanyIdFailedAction,
    LoadUpcomingInstantsByCompanyIdSucceededAction
} from "./actionTypes";
import { InstantHeader } from "../../domain/entities/instantHeader";


export const loadUpcomingInstantsByCompanyId = (companyId: string): LoadUpcomingInstantsByCompanyIdAction => ({
    type   : LOAD_UPCOMING_INSTANTS_BY_COMPANY_ID,
    payload: companyId
})

export const loadUpcomingInstantsByCompanyIdSucceeded = (instants: InstantHeader[]): LoadUpcomingInstantsByCompanyIdSucceededAction => ({
    type   : LOAD_UPCOMING_INSTANTS_BY_COMPANY_ID_SUCCEEDED,
    payload: instants
})

export const loadUpcomingInstantsByCompanyIdFailed = (error: string): LoadUpcomingInstantsByCompanyIdFailedAction => ({
    type   : LOAD_UPCOMING_INSTANTS_BY_COMPANY_ID_FAILED,
    payload: error
})
