import { combineEpics } from 'redux-observable'
import { instantsRetrieval } from '../usecases/instantslisting/instantsListing.epic'
import { loadUpcomingInstantsByCompanyIdEpic } from "../usecases/loadUpcomingInstantsByCompanyId/epic";
import { loadInstantDetailsEpic } from "../usecases/instantDetails/epic";

export const instantRootEpics = combineEpics(
    instantsRetrieval,
    loadInstantDetailsEpic,
    loadUpcomingInstantsByCompanyIdEpic
)
