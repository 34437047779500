import React, { FC, ReactElement } from "react";
import { InstantHeader } from "../../../domain/entities/instantHeader";
import { Wrapper, Status } from "@googlemaps/react-wrapper";
import Map from "./components/map";
import { Coordinates } from "../../../../common/domain/entities/Coordinates";
import Loading from "../../../../common/adapters/primaries/components/Loading/Loading";

export interface SectionMapProps {
    instants: InstantHeader [];
    fetchCoordinates: Coordinates | undefined;
    loadInstant: (coords: Coordinates, fetchDistance: number) => void;
    removeAllMarkers: boolean;
}

const FullSectionMap: FC<SectionMapProps> = (props: SectionMapProps) => {

    function render(status: Status): ReactElement {
        switch (status) {
            case Status.FAILURE:
                return <div className={'mx-auto pt-12'}>Failed to load map</div>
            case Status.SUCCESS:
                return <Map removeAllMarker={props.removeAllMarkers}
                            loadInstant={props.loadInstant}
                            center={props.fetchCoordinates}
                            instants={props.instants}/>
            case Status.LOADING:
                return <Loading/>
        }
    }

    return (
        <div>
            <div className="relative flex">
                <Wrapper apiKey={process.env.REACT_APP_MAP_KEY ?? ''}
                         version={"beta"}
                         libraries={['marker', 'places']}
                         render={render}
                />
            </div>
        </div>
    );
};

export default FullSectionMap;
