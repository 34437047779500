export class CompanyEmail {
    constructor(
        protected _companyId: string,
        protected _fistName: string,
        protected _lastName: string,
        protected _email: string,
        protected _phoneNumber: string,
        protected _subject: string,
        protected _message: string
    ) {}

    get companyId(): string {
        return this._companyId
    }

    get firstName(): string {
        return this._fistName
    }

    get lastName(): string {
        return this._lastName
    }

    get email(): string {
        return this._email
    }

    get phoneNumber(): string {
        return this._phoneNumber
    }

    get subject(): string {
        return this._subject
    }

    get message(): string {
        return this._message
    }
}
