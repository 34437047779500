import { InstantsLoader } from '../domain/gateways/Instants.loader'
import { ApiInstantsLoader } from "../adapters/secondaries/real/ApiInstants.loader";

export class InstantContextDependenciesFactory {

    static getInstantLoader(): InstantsLoader {
        switch (process.env.REACT_APP_ENV) {
            case 'prod':
            case 'dev':
            default:
                return new ApiInstantsLoader(process.env.REACT_APP_API_URL)
        }
    }
}
