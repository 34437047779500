import React, { FC, Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { CompanyContactFormContainer } from "./form";
import i18n from "../../../../../common/configuration/i18n/i18n";

interface ModalReserveMobileProps {
    renderChildren?: (p: { openModal: () => void }) => React.ReactNode;
}

const MobileContactModal: FC<ModalReserveMobileProps> = ({renderChildren}) => {
    const [showModal, setShowModal] = useState(false);

    //
    function closeModal() {
        setShowModal(false);
    }

    function openModal() {
        setShowModal(true);
    }

    const renderButtonOpenModal = () => {
        return renderChildren ? (
            renderChildren({openModal})
        ) : (
            <button onClick={openModal}>Select Date</button>
        );
    };

    return (
        <>
            {renderButtonOpenModal()}
            <Transition appear show={showModal} as={Fragment}>
                <Dialog as="div"
                        className="HeroSearchFormMobile__Dialog relative z-50"
                        onClose={closeModal}>
                    <div className="fixed inset-0 bg-neutral-100 dark:bg-neutral-900">
                        <div className="flex h-full">
                            <Transition.Child as={Fragment}
                                              enter="ease-out transition-transform"
                                              enterFrom="opacity-0 translate-y-52"
                                              enterTo="opacity-100 translate-y-0"
                                              leave="ease-in transition-transform"
                                              leaveFrom="opacity-100 translate-y-0"
                                              leaveTo="opacity-0 translate-y-52">
                                <Dialog.Panel className="relative h-full flex-1 flex flex-col justify-between overflow-auto">
                                    <>
                                        <div className="absolute left-4 top-4">
                                            <button className="focus:outline-none focus:ring-0"
                                                    onClick={closeModal}>
                                                <XMarkIcon className="w-5 h-5 text-black dark:text-white"/>
                                            </button>
                                        </div>

                                        <div className="flex-1 pt-12 py-1 flex flex-col ">
                                            <div className="flex-1 bg-white dark:bg-neutral-900">
                                                <div className={'nc-CheckOutPagePageMain'}>
                                                    <main className="container mt-11 mb-14 lg:mb-32 flex flex-col-reverse lg:flex-row">
                                                        <div className="w-full lg:w-3/5 xl:w-2/3 lg:pr-10 ">
                                                            <div
                                                                className="w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-8 px-0 sm:p-6 xl:p-8">
                                                                <CompanyContactFormContainer title={i18n.t("company.contact") + 'scaramouche'}/>
                                                            </div>
                                                        </div>
                                                    </main>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </>
    );
};

export default MobileContactModal;
