import { combineReducers } from 'redux';
import { CompanyState } from "./state";
import { loadCompanyDetailsReducer } from "../usecases/loadCompanyDetails/reducer";
import { loadLastCreatedCompaniesReducer } from "../usecases/lastCreatedCompanies/reducer";
import { sendEmailToCompanyReducer } from "../usecases/sendEmailTocompany/reducer";

export const companyRootReducer = combineReducers<CompanyState>({
    details: loadCompanyDetailsReducer,
    listing: loadLastCreatedCompaniesReducer,
    email  : sendEmailToCompanyReducer
})
