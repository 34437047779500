import { Epic, ofType, StateObservable } from 'redux-observable';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { SEND_EMAIL_TO_COMPANY, SendEmailToCompanyAction } from "./actionTypes";
import { AppState } from "../../../redux-configuration/AppState";
import { CompanyService } from "../../domain/gateway/company.service";
import { sendEmailToCompanyFailed, sendEmailToCompanySucceeded } from "./actions";
import { CompanyEmail } from "../../domain/entities/companyEmail";

export const sendEmailToCompanyEpic: Epic = (action$: Observable<SendEmailToCompanyAction>,
                                             store: StateObservable<AppState>,
                                             {companyService}: { companyService: CompanyService }) =>
    action$.pipe(
        ofType(SEND_EMAIL_TO_COMPANY),
        switchMap((action: { payload: CompanyEmail }) => companyService.sendEmailToCompany(action.payload)
            .pipe(
                map(() => sendEmailToCompanySucceeded()),
                catchError(err => of(sendEmailToCompanyFailed(err)))
            )
        )
    )
