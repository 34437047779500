
export const getInstantMarker = (iconType: keyof MarkerType): string => {

    const markers = {
        cultural : require('../../../../assets/markers/cultural.png'),
        sportive :  require('../../../../assets/markers/sport.png'),
        discovery: require('../../../../assets/markers/discovery.png'),
        musical  : require('../../../../assets/markers/musical.png'),
        gourmand : require('../../../../assets/markers/gourmand.png'),
        festive  : require('../../../../assets/markers/festif.png'),
        heart    : require('../../../../assets/markers/heart.png'),
        info     : require('../../../../assets/markers/info.png'),
        offer    : require('../../../../assets/markers/offer.png'),
        gift     : require('../../../../assets/markers/gift.png'),
    }
    return markers[iconType]
}
export interface MarkerType {
    cultural: string;
    sportive: string;
    discovery: string;
    musical: string;
    gourmand: string;
    festive: string;
    heart: string;
    info: string;
    offer: string;
    gift: string;
}
