export class CityHeader {
    constructor(
        protected _name: string,
        protected _zipCode: number,
        protected _preferred: boolean
    ) {
    }

    get name(): string {
        return this._name;
    }

    get zipCode(): number {
        return this._zipCode;
    }

    get preferred(): boolean {
        return this._preferred
    }

}
