import React, { useState } from "react";
import Label from "../Label";
import Input from "../../../../../../common/adapters/primaries/components/Input/Input";
import Textarea from "../../../../../../common/adapters/primaries/components/Textarea/Textarea";
import ButtonPrimary from "../../../../../../common/adapters/primaries/components/Button/ButtonPrimary";
import i18n from "../../../../../../common/configuration/i18n/i18n";
import Select from "../../../../../../common/adapters/primaries/components/Select/Select";
import { CompanyEmail } from "../../../../../domain/entities/companyEmail";
import { FormValidation } from "./validation";
import { CompanyEmailBuilder } from "../../../../../domain/entities/companyEmail.builder";
import { useParams } from "react-router-dom";
import { EmailSubjects } from "../../../../../domain/entities/types/company.types";
import { InstantDetails } from "../../../../../../instant/domain/entities/instantDetails";

export interface Props {
    title: string;
    loading: boolean;
    instant: InstantDetails | undefined;
    sendEmailToCompany: (email: CompanyEmail) => void;
}

const CompanyContactForm = (props: Props): JSX.Element => {

    const params = useParams();

    const [emailData, setEmailData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '+33',
        subject: 'commande',
        message: '',
    })

    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined)

    const sendMessage = () => {
        const companyId = params.companyId ?? props.instant?.businessId
        if (companyId) {
            const emailToBeSentToCompany = new CompanyEmailBuilder()
                .withFirstName(emailData.firstName)
                .withLastName(emailData.lastName)
                .withEmail(emailData.email)
                .withPhoneNumber(emailData.phoneNumber)
                .withSubject(emailData.subject)
                .withMessage(emailData.message)
                .withCompanyId(companyId)
                .build()
            props.sendEmailToCompany(emailToBeSentToCompany)
        }
    }

    const validateForm = () => {
        if (emailData.firstName.length === 0 || emailData.lastName.length === 0 || emailData.email.length === 0 ||
            emailData.phoneNumber.length === 0 || emailData.message.length === 0) {
            setErrorMessage('Veuillez remplir tous les champs')
        } else if (!FormValidation.validateEmail(emailData.email)) {
            setErrorMessage('Veuillez vérifier votre email')
        } else if (!FormValidation.validatePhoneNumber(emailData.phoneNumber)) {
            setErrorMessage('Veuillez vérifier votre numéro de téléphone')
        } else {
            setErrorMessage(undefined)
            sendMessage()
        }
    }

    const handleInputChange = (key: string, value: string) => {
        setEmailData({
            ...emailData,
            [key]: value
        })
    }

    return (
        <div>
            <h3 className="text-2xl font-semibold">{props.title}</h3>
            <div className="w-14 border-b border-neutral-200 dark:border-neutral-700 my-5"></div>

            <div className="mt-6">
                <div>
                    <Label>{i18n.t("company.first_name")}</Label>
                    <Input value={emailData.firstName}
                           name={'firstName'}
                           className={errorMessage && emailData.firstName.length === 0 ? 'error' : ''}
                           onChange={(e) => handleInputChange(e.target.name, e.target.value)}/>
                </div>

                <div className="mt-2">
                    <Label>{i18n.t("company.last_name")}</Label>
                    <Input value={emailData.lastName}
                           name={'lastName'}
                           className={errorMessage && emailData.lastName.length === 0 ? 'error' : ''}
                           onChange={(e) => handleInputChange(e.target.name, e.target.value)}/>
                </div>

                <div className="mt-2">
                    <Label>{i18n.t("company.email")}</Label>
                    <Input type={'email'}
                           name={'email'}
                           value={emailData.email}
                           className={errorMessage && !FormValidation.validateEmail(emailData.email) ? 'error' : ''}
                           onChange={(e) => handleInputChange(e.target.name, e.target.value)}/>
                </div>

                <div className="mt-2">
                    <Label>{i18n.t("company.phone")}</Label>
                    <Input value={emailData.phoneNumber}
                           name={'phoneNumber'}
                           className={errorMessage && !FormValidation.validatePhoneNumber(emailData.phoneNumber) ? 'error' : ''}
                           placeholder="+33 *** *** ***"
                           onChange={(e) => handleInputChange(e.target.name, e.target.value)}/>
                </div>

                <div className="mt-2">
                    <Label>{i18n.t("company.subject")}</Label>
                    <Select value={emailData.subject}
                            name={'subject'}
                            onChange={(e) => handleInputChange(e.target.name, e.target.value)}>

                        {EmailSubjects.map(item => <option key={item.value} value={item.value}>{item.label}</option>)}
                    </Select>
                </div>

                <div className="mt-2">
                    <Label>{i18n.t("company.message")}</Label>
                    <Textarea value={emailData.message}
                              name={'message'}
                              className={errorMessage && emailData.message.length === 0 ? 'error' : ''}
                              placeholder="..."
                              onChange={(e) => handleInputChange(e.target.name, e.target.value)}/>
                </div>

                <div className="mt-2">
                    <span className={'text-sm error'}>{errorMessage}</span>
                </div>

                <div className="pt-4">
                    <ButtonPrimary className={'w-full'}
                                   onClick={() => validateForm()}>
                        {i18n.t("company.send")}
                    </ButtonPrimary>
                </div>
            </div>
        </div>
    );
};

export default CompanyContactForm;
