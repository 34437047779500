export class TimeLeftHelpers {

    static HumanizeTimeLeft(timeLeft: number): string {
        if (timeLeft < 0)
            return ''
        else if (timeLeft < 60)
            return `${this.stringifySecondPart(this.extractSecondsLeft(timeLeft))}s`
        else if (timeLeft < 3600)
            return `${this.stringifyMinutePart(this.extractMinutesLeft(timeLeft))}mn ` +
                `${this.stringifySecondPart(this.extractSecondsLeft(timeLeft))}s`
        else
            return `${this.stringifyHourPart(timeLeft)}h ` +
                `${this.stringifyMinutePart(this.extractMinutesLeft(timeLeft))}mn ` +
                `${this.stringifySecondPart(this.extractSecondsLeft(timeLeft))}s`
    }

    static HumanizeTimeLeftInMinutes(timeLeft: number): string {
        if (timeLeft < 60) {
            return ''
        } else if (timeLeft < 3600) {
            return `${this.stringifyMinutePart(this.extractMinutesLeft(timeLeft))}mn `
        } else {
            return `${this.stringifyHourPart(timeLeft)}h ` +
                `${this.stringifyMinutePart(this.extractMinutesLeft(timeLeft))}mn `
        }
    }

    static stringifyHourPart(timeLeft: number): string {
        return Math.floor(timeLeft / 3600).toString()
    }

    static stringifyMinutePart(timeLeft: number): string {
        return Math.floor(timeLeft / 60).toString()
    }

    private static extractMinutesLeft(timeLeft: number): number {
        return this.isMoreThanOneHour(timeLeft)
            ? timeLeft % 3600
            : timeLeft
    }

    private static extractSecondsLeft(timeLeft: number): number {
        return this.isMoreThanOneMinute(timeLeft)
            ? timeLeft % 60
            : timeLeft
    }

    private static isMoreThanOneMinute(timeLeft: number): boolean {
        return timeLeft / 60 >= 1
    }

    static stringifySecondPart(timeLeft: number): string {
        return timeLeft.toString()
    }

    private static isMoreThanOneHour(timeLeft: number): boolean {
        return timeLeft / 3600 >= 1
    }
}
