import { connect } from 'react-redux'
import { AppState } from "../../../redux-configuration/AppState";
import { Dispatch } from "redux";
import { Company } from "../../domain/entities/company";
import { companyDetailsSelector, loadCompanyDetailsLoadingSelector } from "../../usecases/loadCompanyDetails/selector";
import { LoadCompanyDetailsAction } from "../../usecases/loadCompanyDetails/actionTypes";
import { loadCompanyDetails } from "../../usecases/loadCompanyDetails/actions";
import CompanyDetailsContainer from "./companyDetails.container";
import { companyUpcomingInstantsSelector } from "../../../instant/usecases/loadUpcomingInstantsByCompanyId/selectors";
import { InstantHeader } from "../../../instant/domain/entities/instantHeader";
import { lastCreatedCompaniesSelector } from "../../usecases/lastCreatedCompanies/selector";
import { CompanyHeader } from "../../domain/entities/companyHeader";
import { CompanyEmail } from "../../domain/entities/companyEmail";
import { SendEmailToCompanyAction } from "../../usecases/sendEmailTocompany/actionTypes";
import { sendEmailToCompany } from "../../usecases/sendEmailTocompany/actions";

interface StateToPropsType {
    company: Company | undefined;
    loading: boolean;
    instants: InstantHeader[];
    otherCompanies: CompanyHeader[] | undefined;
}

interface DispatchToPropsType {
    loadCompanyDetails: (companyId: string) => void;
    sendEmailToCompany: (email: CompanyEmail) => void;
}

const mapStateToProps = (state: AppState): StateToPropsType => ({
    company       : companyDetailsSelector(state),
    loading       : loadCompanyDetailsLoadingSelector(state),
    instants      : companyUpcomingInstantsSelector(state),
    otherCompanies: lastCreatedCompaniesSelector(state)
})

const mapDispatchToProps = (dispatch: Dispatch): DispatchToPropsType => ({
    loadCompanyDetails: (companyId: string): LoadCompanyDetailsAction => dispatch(loadCompanyDetails(companyId)),
    sendEmailToCompany: (email: CompanyEmail): SendEmailToCompanyAction => dispatch(sendEmailToCompany(email)),
})

export const CompanyDetails = connect(mapStateToProps, mapDispatchToProps)(CompanyDetailsContainer)
