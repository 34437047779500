import { CompanyHeader } from "./companyHeader";

export class CompanyHeaderBuilder {
    protected _id!: string
    protected _name!: string
    protected _type!: string
    protected _image!: string
    protected _status!: boolean
    protected _category!: string
    protected _description!: string
    protected _city!: string
    protected _address!: string
    protected _latitude!: number
    protected _longitude!: number
    protected _blocked!: boolean

    withId(value: string): CompanyHeaderBuilder {
        this._id = value
        return this
    }

    withName(value: string): CompanyHeaderBuilder {
        this._name = value
        return this
    }

    withAddress(value: string): CompanyHeaderBuilder {
        this._address = value
        return this
    }

    withImage(value: string): CompanyHeaderBuilder {
        this._image = value
        return this
    }

    withStatus(value: boolean): CompanyHeaderBuilder {
        this._status = value
        return this
    }

    withCategory(value: string): CompanyHeaderBuilder {
        this._category = value
        return this
    }

    withDescription(value: string): CompanyHeaderBuilder {
        this._description = value
        return this
    }

    withCity(value: string): CompanyHeaderBuilder {
        this._city = value
        return this
    }

    withType(type: string): CompanyHeaderBuilder {
        this._type = type
        return this
    }

    withBlocked(value: boolean): CompanyHeaderBuilder {
        this._blocked = value
        return this
    }

    withLatitude(value: number): CompanyHeaderBuilder {
        this._latitude = value
        return this
    }

    withLongitude(value: number): CompanyHeaderBuilder {
        this._longitude = value
        return this
    }

    withDefaultValues(data: CompanyHeader): CompanyHeaderBuilder {
        this._id = data.id
        this._name = data.name
        this._type = data.type
        this._image = data.image
        this._status = data.status
        this._address = data.address
        this._latitude = data.latitude
        this._longitude = data.longitude
        this._category = data.category
        this._description = data.description
        this._city = data.city
        this._blocked = data.blocked
        return this
    }

    build(): CompanyHeader {
        return new CompanyHeader(
            this._id,
            this._name,
            this._type,
            this._image,
            this._status,
            this._category,
            this._description,
            this._city,
            this._address,
            this._latitude,
            this._longitude,
            this._blocked
        )
    }
}
