import {
    SEND_EMAIL_TO_COMPANY,
    SEND_EMAIL_TO_COMPANY_FAILED,
    SEND_EMAIL_TO_COMPANY_SUCCEEDED,
    SendEmailToCompanyAction, SendEmailToCompanyFailedAction, SendEmailToCompanySucceededAction
} from "./actionTypes";
import { CompanyEmail } from "../../domain/entities/companyEmail";

export const sendEmailToCompany = (email: CompanyEmail): SendEmailToCompanyAction => ({
    type   : SEND_EMAIL_TO_COMPANY,
    payload: email
})

export const sendEmailToCompanySucceeded = (): SendEmailToCompanySucceededAction => ({
    type   : SEND_EMAIL_TO_COMPANY_SUCCEEDED
})

export const sendEmailToCompanyFailed = (error: string): SendEmailToCompanyFailedAction => ({
    type   : SEND_EMAIL_TO_COMPANY_FAILED,
    payload: error
})
