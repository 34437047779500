import { combineReducers } from 'redux'
import { type AppState } from './AppState'
import { instantsRootReducer } from "../instant/configuration/instant.reducers";
import { companyRootReducer } from "../company/configuration/rootReducer";
import { commonRootReducer } from "../common/configuration/common.reducers";

export const reduxReducers = combineReducers<AppState>({
    instant: instantsRootReducer,
    company: companyRootReducer,
    common : commonRootReducer
})
