import { Transition } from "@headlessui/react";
import InstantCard from "./InstantCard";
import React, { FC, Fragment } from "react";
import { useState } from "react";
import { getInstantMarker } from "../../../../domain/entities/types/instantIcons";
import { InstantHeader } from "../../../../domain/entities/instantHeader";
import { AppEventBuilder } from "../../../../../common/domain/entities/logging/appEvent.builder";
import { AppEventsTypes } from "../../../../../common/domain/appEvents.Type";
import { logEvent } from "../../../../../common/usecases/logging/logging.action";
import { store } from "../../../../../App";

interface Props {
    className?: string;
    mapTopLatitude: number;
    mapBottomLatitude: number;
    mapLeftLongitude: number;
    mapRightLongitude: number;
    instant: InstantHeader;
}

const InstantCardContainer: FC<Props> = (props: Props) => {
    const [isOpen, setIsOpen] = useState(false);

    const isMarkerOnTheLeft =
        props.instant.distanceTo({latitude: props.instant.latitude, longitude: props.mapLeftLongitude}) <
        props.instant.distanceTo({latitude: props.instant.latitude, longitude: props.mapRightLongitude})

    const isMarkerOnTheTop = props.instant.distanceTo({
            latitude : props.mapTopLatitude,
            longitude: props.instant.longitude
        }) <
        props.instant.distanceTo({latitude: props.mapBottomLatitude, longitude: props.instant.longitude})


    const horizontalFloatingClassName = isMarkerOnTheLeft ? 'left-full' : 'right-full'
    const verticalFloatingClassName = isMarkerOnTheTop ? 'top-full' : 'bottom-full'
    return (
        <div className={`nc-AnyReactComponent absolute z-50  ${props.className}`}
             data-nc-id="AnyReactComponent"
             onMouseEnter={() => {
                 const instantDetailsEvent = (new AppEventBuilder())
                     .withNamespace(AppEventsTypes.namespace.pro_instant)
                     .withAction(AppEventsTypes.action.preview)
                     .withReference({itemtype: AppEventsTypes.itemType.instant, id: props.instant.id})
                 store.dispatch(logEvent(instantDetailsEvent.build()))
                 setIsOpen(true)
             }
             }
             onMouseLeave={() => setIsOpen(false)}
        >
            <img className={'image-reflexion'} width={44} height={50} src={getInstantMarker(props.instant.category)}
                 alt={'instant.title'}/>
            <Transition
                show={isOpen}
                as={Fragment}
                enter="transition-opacity duration-75"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity duration-150"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
            >
                <div
                    className={`absolute z-50 ${horizontalFloatingClassName} ${verticalFloatingClassName}  pb-3 w-[260px] aspect-w-1`}>
                    <InstantCard instant={props.instant} className="shadow-2xl"/>
                </div>
            </Transition>
        </div>
    );

};

export default InstantCardContainer;
