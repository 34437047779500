export class AppEvent {
    constructor(
        private _namespace: string,
        private _action: string,
        private _references: Reference[]
    ) {
    }

    get namespace(): string {
        return this._namespace;
    }

    get action(): string {
        return this._action;
    }

    get references(): Reference[] {
        return this._references;
    }
}

export interface Reference {
    id: string;
    itemtype: string;
}
