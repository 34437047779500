import { Instant } from './instant'
import { InstantTypes } from "./types/InstantTypes";
import { ActivationDayType } from "./types/timeType";
import { InstantTimeSpecification } from "./specifications/instant.time.specification";
import { Coordinates } from "../../../common/domain/entities/Coordinates";
import { DistanceUtils } from "../../../common/domain/entities/specifications/distanceUtils";
import { sanitizeUrl } from "../../../common/utils/urlSanitizer";

export class InstantHeader extends Instant {

    constructor(
        protected _id: string,
        protected _title: string,
        private _picture: string,
        protected _description: string,
        protected _category: InstantTypes,
        protected _latitude: number,
        protected _longitude: number,
        protected _delay: string,
        protected _activationStartDate: Date,
        protected _activationEndDate: Date,
        protected _originalStartDate: Date,
        protected _businessId: string,
        protected _businessName: string,
        protected _city: string,
        protected _zipCode: number,
        private _activationDayType: ActivationDayType
    ) {
        super(_id, _title, _description, _category, _latitude, _longitude, _delay, _activationStartDate, _activationEndDate, _originalStartDate, _businessId)
    }

    get picture(): string {
        return this._picture
    }

    get delay(): string {
        return this._delay
    }

    get businessName(): string {
        return this._businessName
    }

    get city(): string {
        return this._city;
    }

    get zipCode(): number {
        return this._zipCode
    }

    formattedTimeLeft(): string {
        return InstantTimeSpecification.formatTimeLeft(this.timeLeftInSecond())
    }

    hasFixedActivationDayType(): boolean {
        return this._activationDayType === 'fixed';
    }

    distanceTo(toCoordinates: Coordinates): number {
        return DistanceUtils.calculateDistanceInMeter({
            latitude : this._latitude,
            longitude: this._longitude
        }, toCoordinates)
    }

    publicPath(): string {
        return `/${sanitizeUrl(this._city.trim())}/instant/${sanitizeUrl(this._title.trim())}/${this._id}`
    }
}
