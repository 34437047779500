import {
    LOAD_LAST_CREATED_COMPANIES,
    LOAD_LAST_CREATED_COMPANIES_FAILED,
    LOAD_LAST_CREATED_COMPANIES_SUCCEEDED,
    LoadLastCreatedCompaniesActionTypes
} from "./actionTypes";
import { LoadLastCreatedCompaniesState } from "../../configuration/state";

const initialState: LoadLastCreatedCompaniesState = {
    loading  : false,
    companies: undefined,
    error    : undefined
}

export const loadLastCreatedCompaniesReducer = (state = initialState, action: LoadLastCreatedCompaniesActionTypes): LoadLastCreatedCompaniesState => {
    switch (action.type) {
        case LOAD_LAST_CREATED_COMPANIES:
            return {
                ...state,
                loading: true,
                error  : undefined
            }
        case LOAD_LAST_CREATED_COMPANIES_SUCCEEDED:
            return {
                loading  : false,
                companies: action.payload,
                error    : undefined
            }
        case LOAD_LAST_CREATED_COMPANIES_FAILED:
            return {
                loading  : false,
                companies: undefined,
                error    : action.payload
            }
        default:
            return state
    }
}
