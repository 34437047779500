import React, { FC } from "react";
import { CompanyContactFormContainer } from "./form";
import i18n from "../../../../../common/configuration/i18n/i18n";

const CompanyContactSection: FC = () => {

    return (
        <div className="hidden lg:block flex-grow mt-14 lg:mt-0">
            <div className="sticky top-28">
                <div className="listingSectionSidebar__wrap shadow-xl">
                    <CompanyContactFormContainer title={i18n.t("company.contact_us")}/>
                </div>
            </div>
        </div>
    );
};

export default CompanyContactSection;
