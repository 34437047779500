import { AppState } from "../../../redux-configuration/AppState";
import { Epic, ofType, StateObservable } from 'redux-observable';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { LOAD_UPCOMING_INSTANTS_BY_COMPANY_ID, LoadUpcomingInstantsByCompanyIdAction } from "./actionTypes";
import {
    loadUpcomingInstantsByCompanyIdFailed,
    loadUpcomingInstantsByCompanyIdSucceeded
} from "./actions";
import { InstantHeader } from "../../domain/entities/instantHeader";
import { InstantsLoader } from "../../domain/gateways/Instants.loader";

export const loadUpcomingInstantsByCompanyIdEpic: Epic = (action$: Observable<LoadUpcomingInstantsByCompanyIdAction>,
                                                          store: StateObservable<AppState>,
                                                          {instantsLoader}: { instantsLoader: InstantsLoader }) =>
    action$.pipe(
        ofType(LOAD_UPCOMING_INSTANTS_BY_COMPANY_ID),
        switchMap(action => instantsLoader.loadUpcomingInstantsByCompanyId(action.payload)
            .pipe(
                map((instants: InstantHeader[]) => loadUpcomingInstantsByCompanyIdSucceeded(instants)),
                catchError(error => of(loadUpcomingInstantsByCompanyIdFailed(error)))
            ))
    )
