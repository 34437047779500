import { InstantHeaderBuilder } from "../../../instant/domain/entities/instantHeader.builder";

export const HomepageInstantsSelection = [
    new InstantHeaderBuilder()
        .withId("f97875db-5214-45ba-b25a-d2197e1df0e1")
        .withTitle("« Joséphine Baker, le musical » à Bobino !")
        .withCategory("musical")
        .withCity("Paris 14e")
        .withPicture("https://cdn.vivezclichy.fr/upload/instant/6e2c3e5e-f636-4360-9bc1-0f19da312d86-93dfb9e0bd33fcedebc88a649347e363.jpg")
        .withDescription("Sa vie est un roman ! Retrouvez le spectacle « Joséphine Baker, le musical » à Bobino jusqu'au 13 décembre.")
        .build(),
    new InstantHeaderBuilder()
        .withId("df2112b9-11b5-4ce5-8a3b-1f48bedffb29")
        .withTitle("Sherlock Holmes, l’aventure Musicale")
        .withCategory("info")
        .withCity("Paris 13e")
        .withPicture("https://cdn.vivezclichy.fr/upload/instant/4e757582-f8f6-47f1-94f8-858e2ea36289-02521acf360426e63b548f24cd75e8ba.jpg")
        .withDescription("Au rendez-vous : une grande aventure teintée de mystères, d’étranges intrigues et de conspirations inattendues ! Pour résoudre cette énigme, nos héros devront parcourir le monde, percer les secrets des Indes, découvrir les rites du Mexique")
        .build(),
    new InstantHeaderBuilder()
        .withId("29264732-345d-47b5-a90e-406c46e39212")
        .withTitle("Le Roi Lion")
        .withCategory("info")
        .withCity("Paris 9e")
        .withPicture("https://cdn.vivezclichy.fr/upload/instant/4a2f3dd2-1a0a-449d-9a36-42151b3473a8-c9c11428bb19f0c4ed2b15f383b21392.jpg")
        .withDescription("Venez découvrir la comédie musicale primée de Disney, Le Roi Lion, qui a déjà séduit plus de 110 millions de spectateurs à travers le monde. Couleurs magnifiques, effets époustouflants et musique enchanteresse, elle conte l’histoire")
        .build(),
    new InstantHeaderBuilder()
        .withId("e6cd97f2-5065-43b5-9a88-9263b8e7986e")
        .withTitle("Le cirque Bormann présente « Voyage dans le temps »")
        .withCategory("heart")
        .withCity("Paris 15e")
        .withPicture("https://cdn.vivezclichy.fr/upload/instant/e6ac3494-78c8-4e68-99bd-8945ac4aabdb-db72290986213607a908f9f796ba6869.jpg")
        .withDescription("Le cirque Bormann vous présente, son nouveau spectacle « Voyage dans le temps », qui vous fera vivre une expérience unique sous son chapiteau baroque et authentique.")
        .build(),
    new InstantHeaderBuilder()
        .withId("0445df0e-1b11-401c-8c41-abc3db696c62")
        .withTitle("Exposition « Paris, un centenaire olympique » : 100 ans d'histoire des Jeux")
        .withCategory("cultural")
        .withCity("Paris 20e")
        .withPicture("https://cdn.vivezclichy.fr/upload/instant/904945f8-aab6-43a7-aaa2-cedfc24fd3e1-0438157840795da75796780ae8e3625e.jpg")
        .withDescription("À l’occasion du centenaire de l’Olympiade parisienne de 1924, l'exposition « Paris, un centenaire olympique » permet de replacer les Jeux de Paris 2024 dans une perspective historique ainsi que dans un contexte international et français.")
        .build(),
    new InstantHeaderBuilder()
        .withId("aa3f341f-7f7a-45fd-bd20-3ca3d864ac33")
        .withTitle("Les Folies Gruss 2023-2024 - Jubilé de la Compagnie A. Gruss 50 ans à Paris, 50 créations, ça se fête ! « Ici, les artistes sont vos hôtes »")
        .withCategory("heart")
        .withCity("Paris 16e")
        .withPicture("https://cdn.vivezclichy.fr/upload/instant/0631092e-7e0b-48f3-aef7-82ea6e693f50-5c01bb180db60ae5e028d137180ae0d2.jpg")
        .withDescription("À année exceptionnelle, spectacle exceptionnel ! La Compagnie Alexis Gruss va encore vous surprendre avec un spectacle musical, équestre et aérien « fait-maison ». Cette année toujours, les artistes sont vos hôtes, et ils accueillent en")
        .build(),
    new InstantHeaderBuilder()
        .withId("3a08891a-3d0a-4af0-8046-f0f5ccb158b6")
        .withTitle("« Félins » au Muséum national d'Histoire naturelle")
        .withCategory("cultural")
        .withCity("Paris 5e")
        .withPicture("https://cdn.vivezclichy.fr/upload/instant/ba4cb5bd-d14c-4936-9095-a771d5898f7c-aeef99f96713cb17edf7ed6271eace3f.jpg")
        .withDescription("En 2023, le Muséum national d’Histoire naturelle met à l’honneur une exceptionnelle famille d’animaux présents sur toute la planète : les félins.")
        .build(),
    new InstantHeaderBuilder()
        .withId("6d59b399-5517-4ffb-9bf6-d4b189a4c9ef")
        .withTitle("Black Legends Le Musical")
        .withCategory("musical")
        .withCity("Paris 13e")
        .withPicture("https://cdn.vivezclichy.fr/upload/instant/7ba719cd-ccd1-40a6-833b-652ca0e5e7da-5af227e1cfaf7dd7d6dc114cc5de7356.png")
        .withDescription("Venez vivre ou revivre ce spectacle hors normes qui a déjà fait chanter, danser et se lever plus de 90 000 spectateurs lors de la première saison. Black Legends est une plongée dans une autre histoire de l’Amérique au travers de vibrations")
        .build()
]
