import i18n from "../../../../common/configuration/i18n/i18n";

export type InstantTypes =
    'cultural'
    | 'sportive'
    | 'discovery'
    | 'gourmand'
    | 'festive'
    | 'heart'
    | 'info'
    | 'offer'
    | 'gift'
    | 'musical'

export interface TypedCategoryType {
    type: 'all' | 'followed';
    categories: InstantTypes[];
}

type InstantCategoryType = {
    [key: string]: string
}
export const InstantCategories: InstantCategoryType = {
    cultural : i18n.t('instant.cultural'),
    sportive : i18n.t('instant.sportive'),
    discovery: i18n.t('instant.discovery'),
    gourmand : i18n.t('instant.gourmand'),
    festive  : i18n.t('instant.festive'),
    heart    : i18n.t('instant.heart'),
    info     : i18n.t('instant.info'),
    offer    : i18n.t('instant.offer'),
    gift     : i18n.t('instant.gift'),
    musical  : i18n.t('instant.musical')
}
