export const InstantFrI18n = {
    special_offers            : 'Bon plan',
    instant_schedule          : "{{date}} de {{startHour}} à {{endHour}}",
    time_left_status_starts_on: 'Début',
    time_left_status_ends_on  : 'Fin',
    tomorrow                  : 'Demain',
    today                     : 'Aujourd\'hui',
    on                        : 'à',
    time_left_status_expired  : 'Terminé',
    in                        : 'dans',
    events                    : 'Événements',
    cultural                  : 'Culturel',
    sportive                  : 'Sportif',
    discovery                 : 'Découverte',
    gourmand                  : 'Gourmand',
    festive                   : 'Festif',
    heart                     : 'Coup de coeur',
    info                      : 'Info',
    offer                     : 'Offre',
    gift                      : 'Cadeau',
    musical                   : 'Musical',
    chooseTime                : 'Choisissez l\'heure',
    other_instants            : 'Autres instants de {{name}}',
    you_can_like              : 'Vous pouvez aussi aimer',
    our_suggestions           : 'Nos suggestions de sorties',
    popular_instants          : 'Les sorties les plus populaires sur Paris en ce moment'
}
