import React, { FC, useEffect } from "react";
import { InstantDetails } from "../../../domain/entities/instantDetails";
import { Company } from "../../../../company/domain/entities/company";
import { useParams } from "react-router-dom";
import BackgroundSection from "../../../../common/adapters/primaries/components/BackgroundSection/BackgroundSection";
import InstantDetailsHeader from "./components/instant.header";
import { InstantDetailsDescription } from "./components/instant.description";
import { InstantDetailsMap } from "./components/instant.map";
import VerticalInstantsListing from "../components/list/vertical/verticalInstants.listing";
import { InstantHeader } from "../../../domain/entities/instantHeader";
import i18n from "../../../../common/configuration/i18n/i18n";
import { AppEventBuilder } from "../../../../common/domain/entities/logging/appEvent.builder";
import { AppEventsTypes } from "../../../../common/domain/appEvents.Type";
import { Dispatch } from "redux";
import { LogEventAction } from "../../../../common/usecases/logging/analytics.types";
import { useDispatch } from "react-redux";
import { logEvent } from "../../../../common/usecases/logging/logging.action";
import { Helmet } from "react-helmet";
import Loading from "../../../../common/adapters/primaries/components/Loading/Loading";
import CompanyContactSection from "../../../../company/adapters/primaries/components/contact/companyContact.section";

export interface Props {
    instant: InstantDetails | undefined;
    company: Company | undefined;
    otherInstants: InstantHeader[] | undefined;
    loading: boolean;
    loadInstantDetails: (instantId: string) => void;
}

const InstantDetailsContainer: FC<Props> = (props: Props) => {

    const params = useParams();
    const dispatch: Dispatch<LogEventAction> = useDispatch();

    useEffect(() => {
        if (params.instantId) {
            props.loadInstantDetails(params.instantId);
        }
    }, []);

    useEffect(() => {
        if (props.instant) {
            const instantDetailsEvent = (new AppEventBuilder())
                .withNamespace(AppEventsTypes.namespace.pro_instant)
                .withAction(AppEventsTypes.action.display)
                .withReference({itemtype: AppEventsTypes.itemType.instant, id: props.instant.id})
            dispatch(logEvent(instantDetailsEvent.build()))
        }
    }, [props.instant?.id]);

    useEffect(() => {
        if (params.instantId && props.instant && params.instantId !== props.instant.id) {
            props.loadInstantDetails(params.instantId);
        }
    }, [params.instantId]);

    const getInstants = (): InstantHeader[] => {
        if (props.otherInstants && props.instant) {
            return props.otherInstants.filter(instant => instant.id !== props.instant?.id).slice(0, 4)
        }
        return []
    }

    if (props.instant) {
        return (
            <div className="ListingDetailPage">
                <Helmet>
                    <title>Vivez ICI | {props.instant.title}</title>
                    <meta property="og:title" key="og:title" content={`Vivez ICI | ${props.instant.title}`}/>
                    <meta property="og:url" key="og:url" content={`https://vivezici.fr${props.instant.publicPath()}`}/>
                    <meta property="og:description" key="og:description" content={props.instant.shortDescription}/>
                    <meta property="og:image" key="og:image" content={props.instant.imagesURL[0]}/>
                </Helmet>

                <div className="container ListingDetailPage__content">
                    <div className="nc-ListingStayDetailPage">
                        {/*  HEADER */}
                        <InstantDetailsHeader instant={props.instant}
                                              company={props.company}/>

                        {/* MAIN */}
                        <main className="relative z-10 mt-11 flex flex-col lg:flex-row ">
                            {/* CONTENT */}
                            <div className="w-full lg:w-3/5 xl:w-2/3 space-y-8 lg:space-y-10 lg:pr-10">
                                <InstantDetailsDescription instant={props.instant}/>

                                <InstantDetailsMap instant={props.instant}/>
                            </div>

                            <CompanyContactSection/>
                        </main>
                    </div>
                </div>


                {getInstants().length > 0 ?
                    <div className="relative my-24">
                        <BackgroundSection/>

                        <VerticalInstantsListing title={i18n.t("instant.other_instants", {name: props.company?.name ?? ''})}
                                                 subTitle={i18n.t("instant.you_can_like")}
                                                 instants={getInstants()}/>
                    </div>
                    : <div className={'py-14'}/>}
            </div>
        );
    }
    return <Loading/>;
};

export default InstantDetailsContainer;
