import React, { FC, useState } from "react";
import { InstantTypes } from "../../../../domain/entities/types/InstantTypes";

interface Props {
    onCategoryChanges: (catIds: InstantTypes[]) => void;
    isNotFetching: boolean;
}

const CategoryButtons: FC<Props> = (props: Props) => {

    const [selectedSpan, setSelectedSpan] = useState<number | undefined>(undefined)
    const renderCategoryButton = (props: Props) => {
        const classes = 'block cursor-pointer py-4'
        return (
            <div
                className={"w-full relative flex items-center justify-between border border-neutral-200 rounded-full shadow hover:shadow-md transition-all visible bg-white overflow-hidden"}>

                <div className="flex items-center font-medium text-sm">
                    <span onClick={() => {
                        if (props.isNotFetching) {
                            setSelectedSpan((prevState) => {
                                if (prevState === 1) {
                                    props.onCategoryChanges([])
                                    return undefined
                                } else {
                                    props.onCategoryChanges(['gift'])
                                    return 1
                                }
                            })
                        }
                    }}
                          className={`pl-6 pr-5 ${classes} ${selectedSpan === 1 ? 'bg-[#fdcb03] text-white' : ''}`}>Bon plan</span>

                    <span className="h-5 w-[1px] bg-neutral-300 dark:bg-neutral-700"></span>

                    <span onClick={() => {
                        if (props.isNotFetching) {
                            setSelectedSpan((prevState) => {
                                if (prevState === 2) {
                                    props.onCategoryChanges([])
                                    return undefined
                                } else {
                                    props.onCategoryChanges(['cultural', 'sportive', 'discovery', 'heart', 'festive', 'info', 'musical'])
                                    return 2
                                }
                            })
                        }
                    }}
                          className={`px-5 ${classes} ${selectedSpan === 2 ? 'bg-[#fdcb03] text-white' : ''}`}>Évènement</span>


                    <span className="h-5 w-[1px] bg-neutral-300 dark:bg-neutral-700"></span>

                    <span onClick={() => {
                        if (props.isNotFetching) {
                            setSelectedSpan((prevState) => {
                                if (prevState === 3) {
                                    props.onCategoryChanges([])
                                    return undefined
                                } else {
                                    props.onCategoryChanges(['gourmand', 'offer'])
                                    return 3
                                }
                            })
                        }
                    }}
                          className={`pl-5 pr-6 ${classes} ${selectedSpan === 3 ? 'bg-[#fdcb03] text-white' : ''}`}>Service</span>
                </div>
            </div>
        );
    };

    return (
        <div className="relative px-4 lg:container items-center justify-center h-[88px] flex">
            <div className=" mx-auto">
                <div className="lg:block">
                    {renderCategoryButton(props)}
                </div>
            </div>

            <div
                className="hidden md:flex relative z-10 flex-1 items-center justify-end text-neutral-700 dark:text-neutral-100">
            </div>
        </div>
    );
};

export default CategoryButtons;
