import { CompanyEmail } from "./companyEmail";

export class CompanyEmailBuilder {
    protected _companyId!: string
    protected _firstName!: string
    protected _lastName!: string
    protected _email!: string
    protected _phoneNumber!: string
    protected _subject!: string
    protected _message!: string

    withCompanyId(value: string): CompanyEmailBuilder {
        this._companyId = value
        return this
    }

    withFirstName(value: string): CompanyEmailBuilder {
        this._firstName = value
        return this
    }

    withLastName(value: string): CompanyEmailBuilder {
        this._lastName = value
        return this
    }

    withEmail(value: string): CompanyEmailBuilder {
        this._email = value
        return this
    }

    withPhoneNumber(value: string): CompanyEmailBuilder {
        this._phoneNumber = value
        return this
    }

    withSubject(value: string): CompanyEmailBuilder {
        this._subject = value
        return this
    }

    withMessage(value: string): CompanyEmailBuilder {
        this._message = value
        return this
    }

    build(): CompanyEmail {
        return new CompanyEmail(
            this._companyId,
            this._firstName,
            this._lastName,
            this._email,
            this._phoneNumber,
            this._subject,
            this._message
        )
    }
}
