import { Observable } from 'rxjs';
import { ajax, AjaxResponse } from 'rxjs/ajax';
import { HttpClient } from "../../../domain/gateway/httpClient";

export class ObservableAjaxHttpClient implements HttpClient {

    get<R>(url: string, headers?: Record<string, string>): Observable<R> {
        return ajax.getJSON<R>(url, headers)
    }

    post<T>(url: string, body?: object | string, headers?: Record<string, string>): Observable<AjaxResponse<T>> {
        return ajax.post(url, body, headers)
    }
}
