import { Coordinates } from "../../../common/domain/entities/Coordinates";
import { BusinessDistanceSpecifications } from "../distance.specifications";
import { sanitizeUrl } from "../../../common/utils/urlSanitizer";

export class CompanyHeader {
    constructor(
        private _id: string,
        private _name: string,
        private _type: string,
        private _image: string,
        private _status: boolean,
        private _category: string,
        private _description: string,
        private _city: string,
        private _address: string,
        private _latitude: number,
        private _longitude: number,
        private _blocked: boolean
    ) {
    }

    get id(): string {
        return this._id;
    }

    get name(): string {
        return this._name;
    }

    nameClean(): string {
        return sanitizeUrl(this._name);
    }

    get image(): string {
        return this._image;
    }

    get type(): string {
        return this._type;
    }
    get status(): boolean {
        return this._status
    }

    get category(): string {
        return this._category;
    }
    get description(): string {
        return this._description;
    }

    get city(): string {
        return sanitizeUrl(this._city);
    }

    get address(): string {
        return this._address;
    }

    get latitude(): number {
        return this._latitude;
    }

    get longitude(): number {
        return this._longitude;
    }

    get blocked(): boolean {
        return this._blocked
    }

    distanceWithUnit(userPosition: Coordinates): string {
        return BusinessDistanceSpecifications.calculateDistance({
            latitude : this._latitude,
            longitude: this._longitude
        }, userPosition)
    }

    distanceInMeter(userPosition: Coordinates): number {
        return BusinessDistanceSpecifications.calculateDistanceInMeter({
            latitude : this._latitude,
            longitude: this._longitude
        }, userPosition)
    }

    publicPath():string {
        return `/${sanitizeUrl(this._city.trim())}/${this._type}/${sanitizeUrl(this._category)}/${sanitizeUrl(this._name)}/${this._id}`;
    }
}
