import React, { FC } from "react";
import { InstantDetails } from "../../../../domain/entities/instantDetails";
import { Company } from "../../../../../company/domain/entities/company";
import Badge from "../../../../../common/adapters/primaries/components/Badge/Badge";
import Avatar from "../../../../../common/adapters/primaries/components/Avatar/Avatar";
import { InstantCategories } from "../../../../domain/entities/types/InstantTypes";
import { Link } from "react-router-dom";

export interface Props {
    instant: InstantDetails;
    company: Company | undefined;
}

const InstantDetailsHeader: FC<Props> = (props: Props) => {
    return (
        <header className="rounded-md sm:rounded-xl">
            <div className="relative grid grid-cols-2 gap-10">
                <div
                    className="col-span-2 row-span-2 sm:row-span-1 sm:col-span-1 relative overflow-hidden rounded-md sm:rounded-2xl border border-neutral-200">
                    <img className="rounded-md sm:rounded-2xl w-full h-full"
                         src={props.instant.imagesURL[0]}
                         alt={props.instant.title}
                         sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 50vw"/>
                </div>

                <div
                    className="col-span-2 row-span-2 sm:row-span-1 sm:col-span-1 relative overflow-hidden">
                    <div className="listingSection__wrap flex flex-col justify-between h-full">
                        <div className={'!space-y-6'}>
                            <div className="flex justify-between items-center">
                                <Badge name={InstantCategories[props.instant.category]}/>
                            </div>

                            <h1 className="text-2xl sm:text-3xl lg:text-4xl font-semibold">
                                {props.instant.title}
                            </h1>

                            <div className="flex items-center space-x-4">
                                <span>{props.instant.shortDescription}</span>
                            </div>

                            {props.company &&
                                <React.Fragment>
                                    <div className="flex items-center">
                                        <Avatar hasChecked
                                                imgUrl={props.company.profileImageUrl.replace('dev.', '')}
                                                name={props.company.name}
                                                sizeClass="h-16 w-16"
                                                radius="rounded-full"/>
                                        <div className="ml-3">
                                            <span className="text-neutral-500">
                                                Crée par{" "}
                                                <span className="text-neutral-900 font-medium hover:text-primary-6000">
                                                    <Link to={`${props.company.publicPath()}`}>{props.company.name}</Link>
                                                </span>
                                            </span>

                                            {props.company.getBackLink() && (
                                                <span className="text-neutral-900 font-medium text-sm mt-0.5 block hover:text-primary-6000">
                                                    <Link to={props.company.getBackLink() as string}>
                                                        {props.company.getBackLink()?.replace('https://', '').replace('http://', '')}
                                                    </Link>
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                </React.Fragment>
                            }
                        </div>

                        <div className={'!space-y-6'}>
                            <div className="w-full border-b border-neutral-100 dark:border-neutral-700"/>

                            <div
                                className="flex items-center justify-between space-x-8 xl:space-x-12 text-sm text-neutral-700 dark:text-neutral-300">
                                <div className="flex items-center space-x-3">
                                    <i className=" las la-clock text-2xl"></i>
                                    <span>{props.instant.formattedTimeLeftStatus()} {props.instant.formattedTimeLeftInMinutes()}</span>
                                </div>

                                <div className="flex items-center space-x-3">
                                    <i className=" las la-map-marker-alt text-2xl"></i>
                                    <span>{props.instant.address}, {props.instant.city}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
};

export default InstantDetailsHeader;
