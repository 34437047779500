import React, { FC, useEffect, useRef, useState } from "react";
import FullSectionMap from "../../../../instant/adapters/primaries/map/FullSectionMap";
import { InstantHeader } from "../../../../instant/domain/entities/instantHeader";
import { Coordinates } from "../../../../common/domain/entities/Coordinates";
import CategoryButtons from "../../../../instant/adapters/primaries/map/components/categoryButton";
import { InstantTypes, TypedCategoryType } from "../../../../instant/domain/entities/types/InstantTypes";

export interface Props {
    className?: string;
    instants: InstantHeader[];
    fetchCoordinates: Coordinates | undefined;
    fetchInstantLoading: boolean;
    loadInstant: (coords: Coordinates, fetchDistance: number, clearExistingInstants: boolean) => void;
    setCategory: (cat: TypedCategoryType) => void;
}

const MapSection: FC<Props> = (props: Props) => {
    const fetchCoords = useRef<Coordinates | undefined>(undefined)
    const fetchDistance = useRef<number | undefined>(undefined)
    const [removeMarkers, setRemoveAllMarkers] = useState<boolean>(false)

    useEffect(() => {
        setRemoveAllMarkers(false)
    }, [props.instants])

    return (
        <div className={`nc-SectionHero2 relative ${props.className}`}
             data-nc-id="SectionHero2">

            <h1 className={'hidden'}>VivezIci</h1>

            <div className="relative">
                <FullSectionMap
                    fetchCoordinates={props.fetchCoordinates}
                    removeAllMarkers={removeMarkers}
                    loadInstant={(coords: Coordinates, distance: number) => {
                        fetchCoords.current = coords
                        fetchDistance.current = distance
                        props.loadInstant(coords, distance, false)
                    }}
                    instants={props.instants}/>
            </div>

            <div className="absolute left-1/2 -translate-x-1/2 bottom-12">
                <div className="hidden lg:block inset-0">
                    <CategoryButtons
                        isNotFetching={!props.fetchInstantLoading}
                        onCategoryChanges={(catIds: InstantTypes[]) => {
                            props.setCategory({type: "all", categories: catIds})
                            if (fetchCoords.current && fetchDistance.current) {
                                setRemoveAllMarkers(true)
                                props.loadInstant(fetchCoords.current, fetchDistance.current, true)
                            }
                        }}/>
                </div>
            </div>
        </div>
    );
};

export default MapSection;
