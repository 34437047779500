import { InstantsListingState } from '../../configuration/instant.states'
import {
    InstantListingActionsTypes,
    INSTANTS_LISTING_LOADING_FAILED,
    INSTANTS_LISTING_SUCCEEDED,
    RETRIEVE_INSTANTS_LISTING
} from "./instantsListing.types";

const initialInstantsListingState: InstantsListingState = {
    data            : [],
    fetchCoordinates: undefined,
    isLoading       : true,
    error           : null
}

export const instantsListingReducer = (state = initialInstantsListingState, action: InstantListingActionsTypes): InstantsListingState => {
    switch (action.type) {
        case RETRIEVE_INSTANTS_LISTING:
            if (action.payload.clearExistingList) {
                return {
                    ...state,
                    fetchCoordinates: action.payload.coordinates,
                    isLoading       : true,
                    data            : [],
                }
            } else {
                return {
                    ...state,
                    fetchCoordinates: action.payload.coordinates,
                    isLoading       : true,
                }
            }
        case INSTANTS_LISTING_SUCCEEDED: {
            const map = new Map()
            state.data.map((instant) => {
                map.set(instant.id, instant)
            })
            action.payload.map((instant) => {
                map.set(instant.id, instant)
            })

            return {
                ...state,
                data     : Array.from(map).map((item) => item[1]),
                isLoading: false
            }
        }
        case INSTANTS_LISTING_LOADING_FAILED:
            return {
                ...state,
                isLoading: false,
                error    : action.payload
            }
        default:
            return state
    }
}
