import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { Home } from '../../../home/adapters/primaries'
import ScrollToTop from "./components/ScrollToTop";
import SiteHeader from "./components/SiteHeader";
import Footer from "../../../common/adapters/primaries/components/Footer/Footer";
import Page404 from "./components/Page404/Page404";
import { CompanyDetails } from "../../../company/adapters/primaries";
import { InstantDetailsPage } from "../../../instant/adapters/primaries/details";

export function MainNavigation(): JSX.Element {
    return (
        <BrowserRouter>
            <ScrollToTop/>
            <SiteHeader/>

            <Routes>
                <Route index element={<Home/>} path="/"/>
                {
                    /* duplicated on purpose, to prevent overlap with other path*/
                }
                <Route index element={<CompanyDetails/>} path="/:city/commerce/:category/:name/:companyId"/>
                <Route index element={<CompanyDetails/>} path="/:city/entreprise/:category/:name/:companyId"/>
                <Route index element={<CompanyDetails/>} path="/:city/association/:category/:name/:companyId"/>
                <Route index element={<CompanyDetails/>} path="/:city/mairie/:category/:name/:companyId"/>

                <Route element={<InstantDetailsPage/>} path="/:city/instant/:title/:instantId"/>

                <Route element={<Page404/>}/>
            </Routes>

            <Footer/>
        </BrowserRouter>
    )
}
