import React, { FC, Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { isAndroid, isIOS } from 'react-device-detect';
import { Link } from "react-router-dom";

interface Header2Props {
    className?: string;
    size: number;
}


const MainHeader: FC<Header2Props> = ({className = "", size}) => {
    const headerInnerRef = useRef<HTMLDivElement>(null);

    const [isOpen, setIsOpen] = useState(false)

    function closeModal() {
        setIsOpen(false)
    }

    function openModal() {
        setIsOpen(true)
    }

    const renderPopOverContent = () => {
        if (isAndroid) {
            return (
                <React.Fragment>
                    <div className="mt-4">
                        <a href={'https://i75.fr/28'}>
                            <img className={'h-[50px]'}
                                 src={require('../../../../../assets/android-market.png')}/>
                        </a>
                    </div>
                    <p className="text-sm text-[#334279]  mt-4">
                        Cliquer pour télécharger
                    </p>
                </React.Fragment>

            )
        } else if (isIOS) {
            return (
                <React.Fragment>
                    <div className="mt-4">
                        <a href={'https://i75.fr/28'}>
                            <img className={'h-[50px]'}
                                 src={require('../../../../../assets/ios-market.png')}/>
                        </a>
                    </div>
                    <p className="text-sm text-[#334279]  mt-4">
                        Cliquer pour télécharger
                    </p>
                </React.Fragment>
            )
        } else {
            return (
                <React.Fragment>
                    <div className="mt-4">
                        <img className={'h-[200px]'}
                             src={require('../../../../../assets/mobile-qrCode.png')}/>
                    </div>
                    <p className="text-sm text-[#334279]  mt-4">
                        Scanner le QR code pour le télécharger
                    </p>
                </React.Fragment>
            )
        }
    }

    return (
        <>
            <div
                className={`nc-Header nc-Header-3 fixed z-40 top-0 inset-0 bg-black/30 dark:bg-black/50 transition-opacity will-change-[opacity] invisible opacity-0 pointer-events-none`}
            ></div>

            <header ref={headerInnerRef} className={`sticky top-0 z-40 ${className}`}>
                <div
                    className={`bg-white dark:bg-neutral-900 absolute h-full inset-x-0 top-0 transition-transform will-change-[transform,opacity]`}
                ></div>
                <div className="relative px-2 lg:mr-10 md:mr-5 lg:ml-10 md:ml-5 h-[88px] flex items-center justify-center">
                    <div className="flex-1 flex items-center justify-between">
                        {/* Logo (lg+) */}
                        <div className="relative z-10 flex-1">
                            <Link to={"/"}>
                                <img src={require('../../../../../assets/vivezici-logo.png')}
                                     alt={'Vivez ici'}
                                     className={'logo h-auto max-w-full'}
                                     style={{
                                         transition: 'width cubic-bezier(0.4, 0, 0.2, 1) 150ms',
                                         width     : size
                                     }}/>
                            </Link>
                        </div>

                        <div className="hidden lg:block font-semibold text-[#334279]">
                            Vivez votre ville autrement
                        </div>


                        <div className="relative z-10 flex-1 text-neutral-700 dark:text-neutral-100 text-end">
                            <button onClick={openModal}
                                    className={'download-app-btn font-semibold rounded-full border-2 border-[#334279] bg-[#334279] text-sm text-[#FFFFFF] hover:text-[#334279] hover:bg-[#FFFFFF] py-2 px-5'}>
                                Télécharger l&apos;application
                            </button>

                            <Transition appear show={isOpen} as={Fragment}>
                                <Dialog as="div" className="relative z-10" onClose={closeModal} open={isOpen}>
                                    <Transition.Child
                                        as={Fragment}
                                        enter="ease-out duration-300"
                                        enterFrom="opacity-0"
                                        enterTo="opacity-100"
                                        leave="ease-in duration-200"
                                        leaveFrom="opacity-100"
                                        leaveTo="opacity-0"
                                    >
                                        <div className="fixed inset-0 bg-black bg-opacity-25"/>
                                    </Transition.Child>

                                    <div className="fixed inset-0 overflow-y-auto">
                                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                                            <Transition.Child
                                                as={Fragment}
                                                enter="ease-out duration-300"
                                                enterFrom="opacity-0 scale-95"
                                                enterTo="opacity-100 scale-100"
                                                leave="ease-in duration-200"
                                                leaveFrom="opacity-100 scale-100"
                                                leaveTo="opacity-0 scale-95"
                                            >
                                                <Dialog.Panel
                                                    className=" flex flex-col justify-center items-center w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-2 text-left align-middle shadow-xl transition-all">
                                                    <img className={'h-[40px]'}
                                                         src={require('../../../../../assets/vivezici-logo.png')}/>
                                                    <Dialog.Title
                                                        as="h3"
                                                        className="text-lg font-bold mt-5 text-[#334279] text-center">
                                                        Envie de rejoindre la communauté ?
                                                    </Dialog.Title>
                                                    <p className="text-sm text-[#334279]  mt-5">
                                                        C&apos;est plus facile avec l&apos;application VIVEZ-ICI !
                                                    </p>
                                                    {renderPopOverContent()}
                                                </Dialog.Panel>
                                            </Transition.Child>
                                        </div>
                                    </div>
                                </Dialog>
                            </Transition>
                        </div>
                    </div>
                </div>
            </header>
        </>
    );
};

export default MainHeader;
