import { CityHeader } from "../../domain/entities/CityHeader";

export const GET_CITIES_LIST = 'GET_CITIES_LIST'
export const GET_CITIES_LIST_FAILED = 'GET_CITIES_LIST_FAILED'
export const GET_CITIES_LIST_SUCCEEDED = 'GET_CITIES_LIST_SUCCEEDED'

export interface GetCitiesListAction {
    type: typeof GET_CITIES_LIST;
}

export interface GetCitiesListFailedAction {
    type: typeof GET_CITIES_LIST_FAILED;
    payload: string;
}

export interface GetCitiesListSucceededAction {
    type: typeof GET_CITIES_LIST_SUCCEEDED;
    payload: CityHeader[];
}

export type GetCitiesListActionTypes = GetCitiesListAction | GetCitiesListFailedAction | GetCitiesListSucceededAction
