import { AppEvent } from "../../../../domain/entities/logging/AppEvent";
import { LogEventDTO } from "../dto/logEvent.dto";

export class AppEventMapper {
    static convertToLogEventDTO(appEvent: AppEvent): LogEventDTO {
        return {
            timestamp : (new Date()).getTime(),
            namespace : appEvent.namespace,
            action    : appEvent.action,
            references: appEvent.references
        }
    }
}
