import { TimeHelpers } from "./Time.helpers";
import { ApplicationContext } from "../../../../../common/configuration/application.context";
import I18n from "../../../../../common/configuration/i18n/i18n";

const moment = ApplicationContext.getInstance().momentJs();

export class TimeLeftStatus {

    static findTimeLeftStatus(timeLeft: number, isStarted: boolean): string {
        if (timeLeft > 0) {
            return `${this.timeLeftStatusIsStarted(isStarted)} ${this.timeLeftStatusDay(timeLeft)}`
        } else {
            return I18n.t('instant.time_left_status_expired')
        }

    }

    static timeLeftStatusIsStarted(isStarted: boolean): string {
        return isStarted ? I18n.t('instant.time_left_status_ends_on') : I18n.t('instant.time_left_status_starts_on')
    }

    static timeLeftStatusDay(timeLeft: number): string {
        return TimeHelpers.isTodayInstant(timeLeft) ? I18n.t('instant.in') :
            TimeHelpers.isTomorrowInstant(timeLeft) ?
                `${I18n.t('instant.tomorrow')} ${I18n.t('instant.on')}`
                : TimeHelpers.isThisWeekInstant(timeLeft) ?
                    `${moment().add(timeLeft, 'seconds').format('dddd')} ${I18n.t('instant.on')}`
                    : `${moment().add(timeLeft, 'seconds').format('DD MMM')} ${I18n.t('instant.on')}`
    }
}
