export interface OpeningHoursType {
    id: string;
    daytype: string;
    startTime: string;
    endTime: string;
    isPaused: boolean;
}

export interface ClickCollectPicture {
    id: number;
    pictureUrl: string | undefined;
    picture: File | undefined
}

export const EmailSubjects = [
    {
        value: 'commande',
        label: 'Commande'
    },
    {
        value: 'devis',
        label: 'Devis'
    },
    {
        value: 'reservation',
        label: 'Réservation'
    },
    {
        value: 'demande',
        label: 'Demande'
    }
]
