import { Epic, ofType, StateObservable } from 'redux-observable'
import { map, switchMap } from 'rxjs/operators'
import { eventLogged, LOG_EVENT } from './logging.action'
import { LogEventAction } from "./analytics.types";
import { AppState } from "../../../redux-configuration/AppState";
import { EventLogger } from "../../domain/gateway/event.logger";
import { Observable } from "rxjs";

export const appLoggerEpic: Epic = (action$: Observable<LogEventAction>,
                                    store: StateObservable<AppState>,
                                    {eventLogger}: { eventLogger: EventLogger }) =>
    action$.pipe(
        ofType(LOG_EVENT),
        switchMap(action => eventLogger.log(action.payload)
            .pipe(
                map(() => eventLogged())
            )
        )
    )
