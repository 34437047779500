import { InstantHeader } from './instantHeader'
import { InstantTypes } from "./types/InstantTypes";
import { ActivationDayType, DelayType } from "./types/timeType";

export class InstantHeaderBuilder {

    protected _id!: string
    protected _title!: string
    protected _picture!: string
    protected _category!: InstantTypes
    protected _description!: string
    protected _latitude!: number
    protected _longitude!: number
    protected _delay!: DelayType
    protected _activationStartDate!: Date
    protected _activationEndDate!: Date
    protected _originalStartDate!: Date
    protected _businessId!: string
    protected _businessName!: string
    protected _zipCode!: number
    protected _city!: string
    protected _activationDayType!: ActivationDayType

    withId(value: string): InstantHeaderBuilder {
        this._id = value
        return this
    }

    withTitle(value: string): InstantHeaderBuilder {
        this._title = value
        return this
    }

    withPicture(value: string): InstantHeaderBuilder {
        this._picture = value
        return this
    }

    withDescription(value: string): InstantHeaderBuilder {
        this._description = value
        return this
    }

    withCategory(value: InstantTypes): InstantHeaderBuilder {
        this._category = value
        return this
    }

    withLatitude(value: number): InstantHeaderBuilder {
        this._latitude = value
        return this
    }

    withLongitude(value: number): InstantHeaderBuilder {
        this._longitude = value
        return this
    }

    withDelay(value: DelayType): InstantHeaderBuilder {
        this._delay = value
        return this
    }

    withActivationStartDate(value: Date): InstantHeaderBuilder {
        this._activationStartDate = value
        return this
    }

    withActivationEndDate(value: Date): InstantHeaderBuilder {
        this._activationEndDate = value
        return this
    }

    withOriginalStartDate(value: Date): InstantHeaderBuilder {
        this._originalStartDate = value
        return this
    }

    withBusinessId(value: string): InstantHeaderBuilder {
        this._businessId = value
        return this
    }

    withBusinessName(value: string): InstantHeaderBuilder {
        this._businessName = value
        return this
    }

    withCity(value: string): InstantHeaderBuilder {
        this._city = value
        return this
    }

    withZipCode(value: number): InstantHeaderBuilder {
        this._zipCode = value
        return this
    }

    withActivationDayType(value: ActivationDayType): InstantHeaderBuilder {
        this._activationDayType = value
        return this
    }

    build(): InstantHeader {
        return new InstantHeader(
            this._id,
            this._title,
            this._picture,
            this._description,
            this._category,
            this._latitude,
            this._longitude,
            this._delay,
            this._activationStartDate,
            this._activationEndDate,
            this._originalStartDate,
            this._businessId,
            this._businessName,
            this._city,
            this._zipCode,
            this._activationDayType
        )
    }
}
