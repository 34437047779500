import { Company } from "../../../company/domain/entities/company";
import { InstantDetails } from "../../domain/entities/instantDetails";

export const LOAD_INSTANT_DETAILS = 'LOAD_INSTANT_DETAILS'
export const LOAD_INSTANT_DETAILS_SUCCEEDED = 'LOAD_INSTANT_DETAILS_SUCCEEDED'
export const LOAD_INSTANT_DETAILS_FAILED = 'LOAD_INSTANT_DETAILS_FAILED'

export interface LoadInstantDetailsAction {
    type: typeof LOAD_INSTANT_DETAILS;
    payload: string;
}

export interface LoadInstantDetailsSucceededAction {
    type: typeof LOAD_INSTANT_DETAILS_SUCCEEDED;
    payload: {
        instant: InstantDetails;
        company: Company;
    };
}

export interface LoadInstantDetailsFailedAction {
    type: typeof LOAD_INSTANT_DETAILS_FAILED;
    payload: string;
}

export type LoadInstantDetailsActionTypes =
    LoadInstantDetailsAction
    | LoadInstantDetailsSucceededAction
    | LoadInstantDetailsFailedAction
