import { connect } from 'react-redux'
import { Dispatch } from "redux";
import { CompanyEmail } from "../../../../../domain/entities/companyEmail";
import { AppState } from "../../../../../../redux-configuration/AppState";
import { sendEmailToCompanyLoadingSelector } from "../../../../../usecases/sendEmailTocompany/selector";
import { SendEmailToCompanyAction } from "../../../../../usecases/sendEmailTocompany/actionTypes";
import { sendEmailToCompany } from "../../../../../usecases/sendEmailTocompany/actions";
import CompanyContactForm from "./CompanyContact.form";
import { InstantDetails } from "../../../../../../instant/domain/entities/instantDetails";
import { instantDetailsSelector } from "../../../../../../instant/usecases/instantDetails/selectors";

interface StateToPropsType {
    loading: boolean;
    instant: InstantDetails | undefined;
}

interface DispatchToPropsType {
    sendEmailToCompany: (email: CompanyEmail) => void;
}

const mapStateToProps = (state: AppState): StateToPropsType => ({
    loading: sendEmailToCompanyLoadingSelector(state),
    instant: instantDetailsSelector(state)
})

const mapDispatchToProps = (dispatch: Dispatch): DispatchToPropsType => ({
    sendEmailToCompany: (email: CompanyEmail): SendEmailToCompanyAction => dispatch(sendEmailToCompany(email)),
})

export const CompanyContactFormContainer = connect(mapStateToProps, mapDispatchToProps)(CompanyContactForm)
