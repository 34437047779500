import React, { ReactElement } from 'react'
import { Provider } from 'react-redux'
import { reduxStore } from './redux-configuration/store.redux'
import { MainNavigation } from './navigations/adapters/primaries/mainNavigation'

export  const store = reduxStore()

function App (): ReactElement {
  return (
        <Provider store={store}>
            <div className="bg-white text-base dark:bg-neutral-900 text-neutral-900 dark:text-neutral-200">
                <MainNavigation/>
            </div>
        </Provider>
    )
}

export default App
