export function sanitizeUrl(url: string) {
    if (!url) {
        return "about:blank";
    }

    url = url
        .replace(/\s+/g, '-')
        .replace(/\?/g, '')
        .replace(/&/g, '')
        .replace(/'/g, '-')
        .replace(/"/g, '-')
        .replace("/", '')
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .toLowerCase();

    const invalidProtocolRegex = /^(%20|\s)*(javascript|data|vbscript)/im;
    const ctrlCharactersRegex = /[^\x20-\x7EÀ-ž]/gim;
    const urlSchemeRegex = /^([^:]+):/gm;
    const relativeFirstCharacters = [".", "/"];

    function _isRelativeUrlWithoutProtocol(url: string) {
        return relativeFirstCharacters.indexOf(url[0]) > -1;
    }

    const sanitizedUrl = url.replace(ctrlCharactersRegex, "").trim();
    if (_isRelativeUrlWithoutProtocol(sanitizedUrl)) {
        return sanitizedUrl;
    }

    const urlSchemeParseResults = sanitizedUrl.match(urlSchemeRegex);
    if (!urlSchemeParseResults) {
        return sanitizedUrl;
    }

    const urlScheme = urlSchemeParseResults[0];
    if (invalidProtocolRegex.test(urlScheme)) {
        return "about:blank";
    }

    return sanitizedUrl;
}
