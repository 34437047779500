import { catchError, debounceTime, map, switchMap } from 'rxjs/operators'
import { of, Observable } from "rxjs";
import { Epic, ofType, StateObservable } from "redux-observable";
import { InstantsListingLoaded, InstantsListingLoadingFailed } from "./instantsListing.actions";
import {
    RETRIEVE_INSTANTS_LISTING,
    RetrieveInstantsListingAction
} from "./instantsListing.types";
import { InstantsLoader } from "../../domain/gateways/Instants.loader";
import { AppState } from "../../../redux-configuration/AppState";
import { InstantHeader } from "../../domain/entities/instantHeader";

export const instantsRetrieval: Epic = (action$: Observable<RetrieveInstantsListingAction>,
                                        store: StateObservable<AppState>,
                                        {instantsLoader}: { instantsLoader: InstantsLoader }) =>
    action$.pipe(
        ofType(RETRIEVE_INSTANTS_LISTING),
        debounceTime(600),
        switchMap(action => getEndPointWithParams(action, store, instantsLoader)
            .pipe(
                map(instants => {
                    const filteredInstantList: InstantHeader[] = []
                    instants.map((instant: InstantHeader) => {
                        const found: InstantHeader | undefined = filteredInstantList.find((item: InstantHeader) => instant.latitude === item.latitude && instant.longitude === item.longitude)
                        if (!found)
                            filteredInstantList.push(instant)
                    })
                    return InstantsListingLoaded(filteredInstantList)
                }),
                catchError(error => of(InstantsListingLoadingFailed(error)))
            )
        )
    )


const getEndPointWithParams = (action: RetrieveInstantsListingAction, store: StateObservable<AppState>, instantsLoader: InstantsLoader): Observable<InstantHeader[]> => {
    if (store.value.instant.categories.categories.length === 0)
        return of([])
    else
        return instantsLoader.loadAllInstants(action.payload.coordinates, action.payload.distance, store.value.instant.categories.categories, action.payload.date)
}
