import { connect } from 'react-redux'
import { HomeContainer } from './homeContainer'
import { TypedCategoryType } from "../../../instant/domain/entities/types/InstantTypes";
import { InstantHeader } from "../../../instant/domain/entities/instantHeader";
import { Coordinates } from "../../../common/domain/entities/Coordinates";
import { AppState } from "../../../redux-configuration/AppState";
import { instantCategoriesSelector } from "../../../instant/usecases/setInstantCategories/selector";
import {
    fetchCoordinatesSelector,
    instantsListingSelector, isInstantsListingLoadingSelector
} from "../../../instant/usecases/instantslisting/instantsListing.selectors";
import { Dispatch } from "redux";
import { RetrieveInstantsListingAction } from "../../../instant/usecases/instantslisting/instantsListing.types";
import { retrieveInstantsListing } from "../../../instant/usecases/instantslisting/instantsListing.actions";
import { setInstantCategories } from "../../../instant/usecases/setInstantCategories/action";
import { SetInstantCategoriesAction } from "../../../instant/usecases/setInstantCategories/actionType";
import { CompanyHeader } from "../../../company/domain/entities/companyHeader";
import { lastCreatedCompaniesSelector } from "../../../company/usecases/lastCreatedCompanies/selector";
import { loadLastCreatedCompanies } from "../../../company/usecases/lastCreatedCompanies/actions";
import { LoadLastCreatedCompaniesAction } from "../../../company/usecases/lastCreatedCompanies/actionTypes";

interface StateToPropsType {
    categories: TypedCategoryType;
    instants: InstantHeader[];
    companies: CompanyHeader[] | undefined;
    fetchCoordinates : Coordinates | undefined;
    fetchInstantLoading: boolean;
}


interface DispatchToPropsType {
    retrieveInstantsListing: (coordinates: Coordinates, distance: number, clearExistingInstants: boolean) => void;
    setCategory: (cat: TypedCategoryType) => void;
    loadLastCreatedCompanies: () => void;
}

const mapStateToProps = (state: AppState): StateToPropsType => ({
    categories         : instantCategoriesSelector(state),
    instants           : instantsListingSelector(state),
    companies : lastCreatedCompaniesSelector(state),
    fetchCoordinates   : fetchCoordinatesSelector(state),
    fetchInstantLoading: isInstantsListingLoadingSelector(state)
})

const mapDispatchToProps = (dispatch: Dispatch): DispatchToPropsType => ({
    retrieveInstantsListing : (coordinates: Coordinates, distance: number, clearExistingInstants: boolean): RetrieveInstantsListingAction => dispatch(retrieveInstantsListing(coordinates, distance, new Date(), clearExistingInstants)),
    setCategory             : (cat: TypedCategoryType): SetInstantCategoriesAction => dispatch(setInstantCategories(cat)),
    loadLastCreatedCompanies: (): LoadLastCreatedCompaniesAction => dispatch(loadLastCreatedCompanies(92110))
})

export const Home = connect(mapStateToProps, mapDispatchToProps)(HomeContainer)
